import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import locationicon from "../images/location-icon-contact.svg";
import callicon from "../images/call-icon-contact.svg";
import mailicon from "../images/email-icon-contact.svg";
import websiteicon from "../images/website-icon-contact.svg";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { POSTAPI } from "../helper/integration.";

const ContactUs = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSendContact = (data) => {
    toast.promise(
      POSTAPI("https://www.urvibazaar.4born.in/App/contact.php", data),
      {
        loading: "Wait Few Moment !",
        success: (M) => M.message || "Contact Us Successfully ",
        error: "Somthing Wrong",
      }
    );
    reset();
  };
  return (
    <div>
      <Header />
      <div className="aboutfirst mt-103">
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>Contact Us
        </p>
      </div>
      <form
        onSubmit={handleSubmit(handleSendContact)}
        className="container p-main-80"
      >
        <div className="payment-link-option home-heading">
          <p className="mb-0">
            <Link to={"#"}>Get In Touch </Link>
          </p>
          <h2 className="mb-0">Leave us a message!</h2>
        </div>
        <div className="row p-main-80 pb-0">
          <div className="col-md-6 col-12 mb-3 ">
            <input
              type="text"
              class="form-control p-control"
              placeholder="Your Full Name *"
              aria-label="First name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="py-1 text-danger px-1">Please Enter Name !</p>
            )}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <input
              type="email"
              class="form-control p-control"
              id="exampleFormControlInput1"
              placeholder="Your Email *"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <p className="py-1 text-danger px-1">Please Enter Email !</p>
            )}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <input
              type="text"
              class="form-control p-control "
              placeholder="Your Address"
              aria-label="Address"
              {...register("addresh", { required: true })}
            />
            {errors.addresh && (
              <p className="py-1 text-danger px-1">Please Enter Address !</p>
            )}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <input
              type="number"
              class="form-control p-control "
              placeholder="Phone "
              aria-label="Phone  "
              {...register("phoneNu", { required: true })}
            />
            {errors?.phoneNu && (
              <p className="py-1 text-danger px-1">Please Enter Phone !</p>
            )}
          </div>
          <div className="col-12 mb-3">
            <div class="mb-3">
              <textarea
                class="form-control p-control"
                id="exampleFormControlTextarea1"
                rows="8"
                placeholder="Your Message"
                {...register("message", { required: true })}
              ></textarea>
            </div>
            {errors?.message && (
              <p className="py-1 text-danger px-1">Please Enter Phone !</p>
            )}
          </div>
          <button type="submit" className="send-us-now">
            Send Us Now
          </button>
        </div>
      </form>

      <div className="container p-main-80 pt-0">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <div className="contat-icon-d">
              <img src={locationicon} alt="locatio icon"></img>
              <h3>Address</h3>
              <p>
                <Link to={"#"}>8901 Marmora Road, Glasgow, D04 89GR</Link>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <div className="contat-icon-d">
              <img src={callicon} alt="locatio icon"></img>
              <h3>Phone</h3>
              <p>
                <Link to={"tel:(426) 460 8668"}>(426) 460 8668</Link>
              </p>
              <p>
                <Link to={"tel:(317) 799 7654"}>(317) 799 7654</Link>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <div className="contat-icon-d">
              <img src={mailicon} alt="locatio icon"></img>
              <h3>Emaill contact</h3>
              <p>
                <Link to={"mailto:kevin.price@example.com"}>
                  kevin.price@example.com
                </Link>
              </p>
              <p>
                <Link to={"mailto:ryanpatel@example.com"}>
                  ryanpatel@example.com
                </Link>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <div className="contat-icon-d website-p">
              <img src={websiteicon} alt="locatio icon"></img>
              <h3>Website</h3>
              <p>
                <Link to={"#"}>www.organive.store.com</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;

import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import jalebiabout from "../images/jalebiabout.svg";
import jalebi from "../images/jalebi.svg";
import jalebitwo from "../images/jalebi-two.svg";
import { Link } from "react-router-dom";
import { MdOutlineZoomOutMap } from "react-icons/md";
import productone from "../images/productone.svg";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa6";
import reviewusers from "../images/person.png";
import { TbSquareRoundedCheck } from "react-icons/tb";
import { FaPencil } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUser, UploadImage, getUserDetail } from "../redux/userSlice";
import toast from "react-hot-toast";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";
import { useForm } from "react-hook-form";

const Profile = () => {
  const [Image, setImage] = useState("");
  const UserDetail = useSelector(getUserDetail);
  const { register, reset, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const handleUpdateUser = (data) => {
    let obj = {
      id: UserDetail?.id,
      username: data?.username || UserDetail?.username,
      email: data?.email || UserDetail?.email,
      phone: data?.phone || UserDetail?.phone,
      address: data?.address || UserDetail?.address,
    };

    toast
      .promise(POSTAPI(ApiObj.UpdateUser, obj), {
        loading: "Wait Few Moment !",
        error: "Something Wrong !",
        success: "User Update Successfully !",
      })
      .then(() => {
        dispatch(UpdateUser(obj));
      })
      .catch((er) => {
        console.log(er);
      });
  };
  const handleUploadImage = async (e) => {
    try {
      const newData = new FormData();
      newData.append("id", UserDetail?.id);
      newData.append("image", e.target.files[0]);
      const data = await toast.promise(POSTAPI(ApiObj.UploadImage, newData), {
        loading: "Wait Few Moment !",
        success: "Image Upload Successfully !",
        error: "Something Wrong",
      });
      console.log(data)
      dispatch(UploadImage(URL.createObjectURL(e.target.files[0])));
      setImage(URL.createObjectURL(e.target.files[0]));
    } catch (er) { }
  };



  return (
    <div>
      <Header />
      <div className="aboutfirst mt-103">
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>My Account
        </p>
      </div>

      <div className="container p-main-80">
        <div className="form-design">
          <form onSubmit={handleSubmit(handleUpdateUser)}>
            <h2>Your Personal Details</h2>
            <div className="img-height-set">
              <img
                src={UserDetail?.image || Image}
                alt=""
                onError={(e) => (e.currentTarget.src = reviewusers)}
              />
              <input
                type="file"
                id="profile_imag"
                hidden
                onChange={handleUploadImage}
              />
              <div className="pencil-set-img-border">
                <label htmlFor="profile_imag">
                  <FaPencil />
                </label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12 col-md-12 mb-3 ">
                <label for="firstname" class="form-label p-label">
                  First Name <span>*</span>
                </label>
                <input
                  type="text"
                  class="form-control p-control"
                  placeholder="First name"
                  defaultValue={UserDetail?.username}
                  aria-label="First name"
                  {...register("username")}
                />
              </div>
              <div className="col-lg-12 col-md-12 mb-3 ">
                <label
                  for="exampleFormControlInput1"
                  class="form-label p-label"
                >
                  Email address<span>*</span>
                </label>
                <input
                  type="email"
                  class="form-control p-control"
                  id="exampleFormControlInput1"
                  defaultValue={UserDetail?.email}
                  placeholder="name@example.com"
                  {...register("email")}
                />
              </div>
              <div className="col-12 ">
                <label for="companyname" class="form-label p-label">
                  Telephone <span>*</span>
                </label>
                <input
                  type="number"
                  class="form-control p-control"
                  placeholder="9999999999"
                  defaultValue={UserDetail?.phone}
                  aria-label="Company name"
                  {...register("phone")}
                />
              </div>
              <div className="col-12">
                <div className="row mt-3">
                  <div className="col-lg-12 col-md-12 mb-3 ">
                    <label
                      for="exampleFormControlTextarea1"
                      class="form-label  p-label"
                    >
                      Your Address
                    </label>
                    <textarea
                      class="form-control p-control"
                      id="exampleFormControlTextarea1"
                      placeholder="71582 O'Connell Plain, Lednerbury, MN 48227"
                      rows="7"
                      defaultValue={UserDetail?.address}
                      {...register("address")}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="profile-details-edit me-2">
                  <button>Edit</button>
                </div>
                {/* <div className="click-wishlist">
                  <Link to={"/Wishlist"} className="mb-0">
                    <i class="fa-solid fa-trash"></i>
                  </Link>
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";

export const GetUserData = createAsyncThunk("user/detail", async (obj) => {
  const res = await POSTAPI(ApiObj.GetUserDetal, obj);
  return res.data;
});

export const AddCart = createAsyncThunk("user/add-cart", async (obj) => {
  const res = await POSTAPI(ApiObj.AddCart, obj);
  return res.data;
});

export const RemoveCartApi = createAsyncThunk(
  "user/remove-cart",
  async (obj) => {
    const res = await POSTAPI(ApiObj.RemoveCart, obj);
    return res.data;
  }
);

export const AddWishApi = createAsyncThunk("user/add-wish", async (obj) => {
  const res = await POSTAPI(ApiObj.WishAdd, obj);
  return res.data;
});

export const RemoveWishApi = createAsyncThunk(
  "user/remove-wish",
  async (obj) => {
    const res = await POSTAPI(ApiObj.RemoveWish, obj);
    return res.data;
  }
);

import React, { useEffect, useState } from "react";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";

const useGetFilter = () => {
  const [isFilterLoad, setisFilterLoad] = useState(false);
  const [Filters, setFilters] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const MainData = await POSTAPI(ApiObj.ShowFilters);
        setFilters((prev) => MainData?.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisFilterLoad(true);
      }
    })();
  }, []);

  return { isFilterLoad, Filters };
};

export default useGetFilter;

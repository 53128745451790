import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import productone from "../images/productone.svg";
import Person from "../images/person.png";
import NotFound from "../images/not_found.svg";

import { Link, NavLink, useParams } from "react-router-dom";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa6";
import { TbSquareRoundedCheck } from "react-icons/tb";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";
import toast from "react-hot-toast";
import { CiWarning } from "react-icons/ci";
import {
  AddCart,
  AddWishApi,
  RemoveCartApi,
  RemoveWishApi,
} from "../redux/userSliceApi";
import { useDispatch, useSelector } from "react-redux";
import { GetCarts, ShowWishList } from "../redux/userSlice";
import { useCookies } from "react-cookie";

const Cart = () => {
  const { id } = useParams();
  const [countNum, setCountNum] = useState(1);
  const [isLoad, setisLoad] = useState(false);
  const [MainData, setMainData] = useState(null);
  const [StarCheck, setStarCheck] = useState(0);
  const [Message, setMessage] = useState("");
  const [isUpdate, setisUpdate] = useState(false);
  const [OtherData, setOtherData] = useState([]);
  const [Review, setReview] = useState([]);
  const [cookie] = useCookies(["user"]);
  const [Image, setImage] = useState(productone);

  const handleMinusClick = () => {
    setCountNum((prevCount) => prevCount - 1);
  };

  const handlePlusClick = () => {
    setCountNum((prevCount) => prevCount + 1);
  };
  const dispatch = useDispatch();
  const Carts = useSelector(GetCarts);
  const Wishs = useSelector(ShowWishList);
  const handleCart = (id, stock) => {
    if (stock != "true") {
      return toast("Sorry Product is out off stock ! ", {
        icon: <CiWarning size={20} color="#F4D50B" />,
      });
    }
    let obj = {
      user_id: cookie.user,
      product_id: id,
      quantity: countNum,
    };
    toast.dismiss();
    if (Carts.some((el) => el == id)) {
      toast.success("Cart Remove Successfully !");
      dispatch(RemoveCartApi(obj));
    } else {
      toast.success("Cart Add Successfully !");
      dispatch(AddCart(obj));
    }
  };
  const handleWish = (id) => {
    if (!cookie?.user)
      return toast("Please Login First ", {
        icon: <CiWarning size={20} color="#F4D50B" />,
      });
    let obj = {
      user_id: cookie.user,
      product_id: id,
    };
    toast.dismiss();
    if (Wishs.some((el) => el == id)) {
      toast.success("Wish Remove Successfully !");
      dispatch(RemoveWishApi(obj));
    } else {
      toast.success("Wish Add Successfully !");
      dispatch(AddWishApi(obj));
    }
  };

  const handleAddReview = () => {
    let obj = {
      user_id: cookie.user,
      product_id: id,
      review: Message,
      stars: StarCheck,
    };

    toast
      .promise(POSTAPI(ApiObj.AddReview, obj), {
        loading: "Wait Few Moment !",
        success: "Review Successfully !",
        error: "Something Wrong !",
      })
      .then((val) => {
        document.getElementById("closeReviewModal").click();
        setisUpdate(!isUpdate);
      })
      .catch(() => {});
  };
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await POSTAPI(ApiObj.ShowProductDetail, {
          product_id: id,
        });
        let obj = {
          type: data.data.type,
        };
        setReview(data?.reviewData);
        const Other = await POSTAPI(ApiObj.ShowProduct, obj);
        setOtherData((prev) => Other.data);
        setMainData((prev) => data.data);
        setImage((prev) => data.data.img?.[0]);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      <Header />
      <div className="aboutfirst mt-103">
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>About Item
        </p>
      </div>
      {!isLoad ? (
        <div
          className="d-flex  justify-content-center  align-items-center "
          style={{ height: "50vh" }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close shadow-none"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <img
                    src={Image}
                    onError={(e) => (e.currentTarget.src = productone)}
                    alt=""
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container p-main-80">
            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <div className="about-img">
                  <Link
                    to={"#"}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <MdOutlineZoomOutMap />
                  </Link>
                  <img
                    src={Image}
                    onError={(e) => (e.currentTarget.src = productone)}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="row">
                  {MainData?.img?.map((item) => {
                    return (
                      <div className="col-4">
                        <div className="img-about-three">
                          <img
                            src={item}
                            onError={(e) => (e.currentTarget.src = productone)}
                            onClick={() => setImage(item)}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-6 col-12 mb-3">
                <div className="about-product">
                  <h2>{MainData?.name}</h2>
                  <div className="rating-review">
                    <div className="star-five">
                      {[...Array(5).keys()].map((item, index) => {
                        return (
                          <i
                            className={`fa-solid fa-star ${
                              Math.floor(MainData?.avgRate) >= index + 1 &&
                              "star-active"
                            }`}
                          ></i>
                        );
                      })}
                    </div>
                    <p>({MainData?.totalReview} Reviews)</p>
                  </div>
                  <h3>$ {MainData?.price}</h3>
                  <p className="detail-line"> {MainData?.detail}</p>
                  {MainData?.inStock != "true" ||
                  MainData?.stock_quantity < 1 ? (
                    <p className="text-danger ">
                      Sorry Product is Out of Stock !
                    </p>
                  ) : Carts.some((el) => el == MainData?.id) ? (
                    <Link className="con-shoping mb-4" to={"/Cart"}>
                      Continue Shpoping{" "}
                    </Link>
                  ) : (
                    <div className="add-to-cart">
                      <div className="framee">
                        <div className="containerr">
                          <div className="button-plus-minus">
                            <button
                              className="border-0 bg-transparent"
                              id="minus-btn"
                              onClick={() => countNum > 1 && handleMinusClick()}
                            >
                              -
                            </button>
                          </div>
                          <div className="number">
                            <p id="count" className="mb-0">
                              {countNum}
                            </p>
                          </div>
                          <div className="button-plus-minus">
                            <button
                              className="border-0 bg-transparent"
                              id="plus-btn"
                              onClick={() =>
                                MainData.stock_quantity > countNum
                                  ? handlePlusClick()
                                  : toast("Sorry Stock Is Limited ! ", {
                                      icon: (
                                        <CiWarning size={20} color="#F4D50B" />
                                      ),
                                    })
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="tbl-sec-txt">
                        <Link
                          onClick={() => {
                            if (!cookie.user)
                              return toast("Please Login First ", {
                                icon: <CiWarning size={20} color="#F4D50B" />,
                              });
                            handleCart(MainData?.id, MainData.inStock);
                          }}
                          to={`${!cookie.user ? "/Sign-in" : ""}`}
                          className="con-shoping mb-0"
                        >
                          Add To CART{" "}
                          <i
                            className={`fa-solid fa-cart-plus ms-2 ${
                              Carts.some((el) => el == MainData?.id) &&
                              "cart-active"
                            }`}
                          ></i>
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className="click-wishlist">
                    <Link
                      to={`${!cookie.user ? "/Sign-in" : ""}`}
                      onClick={() => {
                        if (!cookie.user)
                          return toast("Please Login First ", {
                            icon: <CiWarning size={20} color="#F4D50B" />,
                          });
                        handleWish(MainData.id);
                      }}
                    >
                      <i
                        className={`fa-regular fa-heart ${
                          Wishs.some((el) => el == MainData?.id) &&
                          "cart-active"
                        }`}
                      ></i>
                    </Link>
                  </div>
                  <div className="category-about">
                    <h4>
                      Categories :
                      <span className="text-capitalize ">
                        {MainData?.type_name?.replace("_", " ")}
                      </span>
                    </h4>
                  </div>
                  <div className="share-about">
                    <h4>Share Now :</h4>
                    <div className="share-about-social">
                      <Link to={"#"}>
                        <i class="fa-brands fa-facebook-f"></i>
                      </Link>
                      <Link to={"#"}>
                        <i class="fa-brands fa-instagram"></i>
                      </Link>
                      <Link to={"#"}>
                        <i class="fa-brands fa-twitter"></i>
                      </Link>
                      <Link to={"#"}>
                        <i class="fa-brands fa-google"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container p-main-80 pt-0">
            <ul
              class="nav nav-pills mb-3 home-pills"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item me-md-4 me-3" role="presentation">
                <button
                  class="nav-link active px-0"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Description
                </button>
              </li>
              <li class="nav-item me-md-4 me-3" role="presentation">
                <button
                  class="nav-link px-0"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Additional information
                </button>
              </li>
              <li class="nav-item me-md-4 me-3" role="presentation">
                <button
                  class="nav-link px-0"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Reviews ({Review?.length})
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="dis-about">
                  <p>{MainData?.detail}</p>
                  {/* <h6>
                <span>
                  <TbSquareRoundedCheck />
                </span>{" "}
                Can Data Visualization Improve The Mobile Web Experience?
              </h6> */}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="dis-about">
                  <p>
                    <p>{MainData?.additional_info}</p>
                  </p>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <div className="review-table mb-3">
                  {Review?.length ? (
                    Review?.map((item) => {
                      return (
                        <div className="review-section-design">
                          <div className="review-users-img">
                            <img
                              src={item?.image}
                              onError={(e) => (e.currentTarget.src = Person)}
                              className="img-fluid "
                              style={{ borderRadius: "50%" }}
                            ></img>
                          </div>
                          <div className="review-users-text">
                            <div className="intext-reiew">
                              <h3>{item?.username}</h3>
                              <div className="review-ration-flex">
                                {[...Array(5).keys()].map((val, index) => {
                                  return (
                                    <i
                                      className={`fa-solid fa-star ${
                                        Math.floor(item?.stars) >= index + 1 &&
                                        "star-active"
                                      }`}
                                    ></i>
                                  );
                                })}
                              </div>
                            </div>
                            <p>{item?.review}</p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex justify-content-center align-items-center   flex-column w-100  ">
                      <img src={NotFound} className="w-25 h-25" />
                      <p>Review Not Found !</p>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-center">
                  <a
                    class="regi-btn w-auto"
                    href="#"
                    onClick={() => {
                      if (!cookie.user)
                        return toast("Please Login First ", {
                          icon: <CiWarning size={20} color="#F4D50B" />,
                        });
                      document.getElementById("review").click();
                    }}
                  >
                    Type Review
                  </a>
                  <a
                    data-bs-toggle="modal"
                    id="review"
                    data-bs-target="#TypeReviews"
                    type="button"
                    hidden
                  ></a>
                </div>
                {/* <!--Type Reviews Modal --> */}
                <div
                  class="modal fade"
                  id="TypeReviews"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div>
                          <div class="mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Add Review
                            </label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="ReviewComment"
                              value={Message}
                              onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                          </div>
                          <div class="star-rating">
                            {[...Array(5).keys()].map((val, index) => {
                              return (
                                <>
                                  <input
                                    type="radio"
                                    id={`${index + 1}-stars"`}
                                    name="ratingCount"
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setStarCheck(e.target.value);
                                    }}
                                    value={5 - index}
                                  />
                                  <label
                                    for={`${index + 1}-stars"`}
                                    class="star"
                                  >
                                    &#9733;
                                  </label>
                                </>
                              );
                            })}
                          </div>
                          <div class="modal-footer border-0 pb-0 justify-content-center">
                            <a
                              href="ExitServiceBooking.php"
                              class="send-us-now-close px-3 redirectLink m-0 me-2"
                              data-bs-dismiss="modal"
                              id="closeReviewModal"
                            >
                              Close
                            </a>
                            <button
                              type="submit"
                              onClick={handleAddReview}
                              class="send-us-now px-3 m-0 me-2"
                              name="revi"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container p-main-80 pt-0">
            <div className="home-heading">
              <h6>Featured Product</h6>
              <h2>You May Like Also</h2>
              <div className="row">
                {OtherData?.slice(0, 4).map((item) => {
                  if (item?.id == MainData?.id) return;
                  return (
                    <div className="col-lg-3 col-md-4 col-6 mb-3">
                      <NavLink to={`/AboutItem/${item?.id}`}>
                        <div className="product-box-bg ">
                          <div className="product-img">
                            <img
                              src={item?.imageUrl}
                              onError={(e) => {
                                e.currentTarget.src = productone;
                              }}
                              className="img-fluid"
                              alt="first"
                            />
                            <div className="div-hide">
                              <div className="cart-wishlist">
                                <Link
                                  onClick={() => {
                                    if (!cookie.user)
                                      return toast("Please Login First ", {
                                        icon: (
                                          <CiWarning
                                            size={20}
                                            color="#F4D50B"
                                          />
                                        ),
                                      });
                                    if (item.stock_quantity < 1) {
                                      return toast(
                                        "Sorry Product is out off stock !  ",
                                        {
                                          icon: (
                                            <CiWarning
                                              size={20}
                                              color="#F4D50B"
                                            />
                                          ),
                                        }
                                      );
                                    }
                                    handleCart(item.id, item.inStock);
                                  }}
                                  to={`${
                                    !cookie.user
                                      ? "/Sign-in"
                                      : item?.inStock != "true"
                                      ? ""
                                      : "/Cart"
                                  }`}
                                  className={`${
                                    Carts.some((el) => el == item.id) &&
                                    "cart-active"
                                  }`}
                                >
                                  <PiShoppingCartSimpleBold />
                                </Link>
                                <Link
                                  onClick={() => {
                                    if (!cookie.user)
                                      return toast("Please Login First ", {
                                        icon: (
                                          <CiWarning
                                            size={20}
                                            color="#F4D50B"
                                          />
                                        ),
                                      });

                                    handleWish(item.id);
                                  }}
                                  to={`${
                                    !cookie.user ? "/Sign-in" : "/WishList"
                                  }`}
                                  className={`${
                                    Wishs.some((el) => el == item.id) &&
                                    "cart-active"
                                  }`}
                                >
                                  <FaRegHeart />
                                </Link>
                              </div>
                            </div>
                            <div className="product-txt-position grn-bg">
                              <h4>{item?.tag}</h4>
                            </div>
                          </div>
                          <h3>{item?.name}</h3>
                          <h5>
                            {item?.price} $<span>80.50 $</span>
                          </h5>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
      {/* <!-- Modal --> */}

      <Footer />
    </div>
  );
};

export default Cart;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import locationicon from "../images/location-icon-contact.svg";
import callicon from "../images/call-icon-contact.svg";
import mailicon from "../images/email-icon-contact.svg";
import websiteicon from "../images/website-icon-contact.svg";
import productone from "../images/productone.svg";
import { Link, useParams } from "react-router-dom";
import useGetOrderDetail from "../hooks/useGetOrderDetail";

const GetDate = (dateString) => {
  let date = new Date(dateString);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const OrderDetail = () => {
  const { id: order_id } = useParams();
  const { isLoad, OrderDetail, Products } = useGetOrderDetail(order_id);
  const handleGetTotalQuantity = () => {
    let total = 0;
    Products?.map((item) => {
      total += Number(item.quantity);
    });
    return total;
  };
  return (
    <div>
      <Header />
      <div className="aboutfirst mt-103">
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>Order Detail
        </p>
      </div>
      <div className="container p-main-80">
        {!isLoad ? (
          <div className="d-flex justify-content-center my-5  align-items-center  w-100">
            <div className="loader"></div>
          </div>
        ) : OrderDetail ? (
          <div className="order-details-page">
            {OrderDetail?.payment_status == "Cancel" ? (
              <div className="order-detail-text spl-paddomg">
                <h2>Order Detail :</h2>
                <h5>Your Payment Canceled </h5>
                <Link to={"/MyOrder"}>VIEW Order</Link>
              </div>
            ) : (
              <>
                {OrderDetail?.order_status == "Cancel" && (
                  <div className="order-detail-text spl-paddomg">
                    <h2>Order Detail :</h2>
                    <h5>Your Order Canceled </h5>
                    <Link to={"/MyOrder"}>VIEW Order</Link>
                  </div>
                )}
                {OrderDetail?.order_status == "Pending" && (
                  <div className="order-detail-text">
                    <h2>Order Detail :</h2>
                    <h3>Your Order In Review </h3>
                    <Link to={"/MyOrder"}>VIEW Order</Link>
                  </div>
                )}
                {OrderDetail?.order_status == "On Going" && (
                  <div className="order-detail-text">
                    <h2>Order Detail :</h2>
                    <h3>Your Order Is On Going</h3>
                    <Link to={"/MyOrder"}>VIEW Order</Link>
                  </div>
                )}
                {OrderDetail?.order_status == "Complete" && (
                  <div className="order-detail-text green-order">
                    <h2>Order Detail :</h2>
                    <h3>
                      Your Order Will Deliver on{" "}
                      <span>{OrderDetail?.deliver_date}</span>
                    </h3>
                    <Link to={"/MyOrder"}>VIEW Order</Link>
                  </div>
                )}
              </>
            )}

            <hr />
            <div className="order-detail-no">
              <div className="d-flex align-items-center justify-content-between">
                <h2>
                  Order No : <span>#{OrderDetail?.order_id}</span>
                </h2>
                {OrderDetail?.coupone_code && (
                  <h2>
                    Coupon Used : <span>{OrderDetail?.coupone_code}</span>
                  </h2>
                )}
              </div>

              <div className="row">
                <div className="col-lg-6  col-12">
                  <div className="shipping-txt">
                    <h3>Billing To :</h3>
                    <p>
                      <span>Name :</span> {OrderDetail?.f_name}{" "}
                      {OrderDetail?.l_name}
                    </p>
                    <p>
                      <span>Address :</span> {OrderDetail?.apartment}{" "}
                      {OrderDetail?.address} <br />
                      <span>City :</span> {OrderDetail.city}
                      <br />
                      <span>Country :</span> {OrderDetail?.country}
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="shipping-txt">
                    <h3>&nbsp;</h3>
                    <p>
                      <span>Total Price :</span> {OrderDetail?.total_price}
                      <br />
                      <span>Total Quantity :</span> {handleGetTotalQuantity()}
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="responsive-table">
                  <table class="table">
                    <thead>
                      <tr className="table-head">
                        <th scope="col">Product</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Products?.map((item) => {
                        return (
                          <tr className="table-data">
                            <td>
                              <div className="table-pro-name">
                                <img
                                  src={item?.image}
                                  onError={(e) => {
                                    e.currentTarget.src = productone;
                                  }}
                                  alt=""
                                  loading="lazy"
                                />
                                <div>
                                  <p>{item?.name}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="tbl-pro-price">
                                <p>{GetDate(OrderDetail?.created_at)}</p>
                              </div>
                            </td>
                            <td>
                              {OrderDetail?.order_status == "Complete" && (
                                <div className="complete-order-tbl">
                                  <p>
                                    <i class="fa-solid fa-circle"></i> completed
                                  </p>
                                  <h6>
                                    ( Order Deliver On <span>22th March</span>{" "}
                                    <span>06:00 AM</span> )
                                  </h6>
                                </div>
                              )}
                              {OrderDetail?.order_status == "Pending" && (
                                <div className="pending-order-tbl">
                                  <p>
                                    <i class="fa-solid fa-circle"></i> Pending
                                  </p>
                                </div>
                              )}
                              {OrderDetail?.order_status == "On Going" && (
                                <div className="processing-order-tbl">
                                  <p>
                                    <i class="fa-solid fa-circle"></i> On Going
                                  </p>
                                </div>
                              )}
                              {OrderDetail?.order_status == "Cancel" && (
                                <div className="cancell-order-tbl">
                                  <p>
                                    <i class="fa-solid fa-circle"></i> Order
                                    Cancelled
                                  </p>
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="tbl-pro-price">
                                <p>
                                  $ {item?.price * item?.quantity}
                                  <span>( {item?.quantity} item )</span>
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {/* <div className="order-details-page p-0 mb-3 mt-2">
          <div className="order-detail-text spl-paddomg">
            <h2>Order Detail:</h2>
            <h5>Your Order Canceled </h5>
            <Link to={"/OrderDetail"}>VIEW Order</Link>
          </div>
          <hr />
          <div className="order-detail-no">
            <h2>
              Order No: <span>#236545</span>
            </h2>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="shipping-txt">
                  <h3>Shipping To:</h3>
                  <p>Ellen Wheelock</p>
                  <p>631 North Coral Court Kernersville, NC 27284</p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="shipping-txt">
                  <h3>Billing To:</h3>
                  <p>Ellen Wheelock</p>
                  <p>631 North Coral Court Kernersville, NC 27284</p>
                </div>
              </div>
            </div>
          </div>
          <div className="order-detail-red ">
            <h6>Why Canceled Order ?</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make
            </p>
          </div>
        </div>

        <div className="order-details-page mb-3 mt-2">
          <div className="order-detail-text green-order">
            <h2>Order Detail:</h2>
            <h3>
              Your Order Will Deliver on <span>15.08.2018</span>
            </h3>
            <Link to={"/OrderDetail"}>VIEW Order</Link>
          </div>
          <hr />
          <div className="order-detail-no ">
            <h2>
              Order No: <span>#236545</span>
            </h2>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="shipping-txt">
                  <h3>Shipping To:</h3>
                  <p>Ellen Wheelock</p>
                  <p>631 North Coral Court Kernersville, NC 27284</p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="shipping-txt">
                  <h3>Billing To:</h3>
                  <p>Ellen Wheelock</p>
                  <p>631 North Coral Court Kernersville, NC 27284</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <Footer />
    </div>
  );
};

export default OrderDetail;

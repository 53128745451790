import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";

const useGetOrders = () => {
  const [isLoad, setisLoad] = useState(false);
  const [Orders, setOrders] = useState([]);
  const [cookie] = useCookies(["user"]);
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await POSTAPI(ApiObj.ShowOrder, { user_id: cookie.user });
        setOrders((prev) => data.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, []);
  return { isLoad, Orders };
};

export default useGetOrders;

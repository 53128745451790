import axios from "axios";
import { ApiObj } from "../utils/Api";

export const RegisterApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(ApiObj.Register, obj);
      if (data.status == "error") {
        reject(data);
      }
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const LoginApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(ApiObj.Login, obj);
      if (data.status == "error") {
        reject(data);
      }
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const SendOtpApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(ApiObj.SendOtp, obj);
      if (data.status == "error") reject(data);
      resolve();
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

export const ChangePasswordApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(ApiObj.ChangePassword, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

export const POSTAPI = (url, obj = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(url, obj);
      if (data.status == "error") throw new Error(data.message);
      resolve(data);
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

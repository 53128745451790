import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useParams } from "react-router-dom";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";
import { MdReportGmailerrorred } from "react-icons/md";
const CancelOrder = () => {
  const { id } = useParams();
  useEffect(() => {
    (async () => {
      try {
        await POSTAPI(ApiObj.ChangeStatus, {
          id: id?.split("-")[0],
          type: "Cancel",
        });
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);
  return (
    <div>
      <Header />
      <div className="container p-main-80 mt-5">
        <div class="h-75 mt-5 d-flex justify-content-center align-items-center">
          <div class="col-md-4">
            <div class="border border-3 border-danger"></div>
            <div class="card  bg-white shadow p-5">
              <div class="mb-4 text-center">
                <MdReportGmailerrorred color="#F73131" size={100} />
              </div>
              <div class="text-center">
                <h1>Something Wrong !</h1>
                <p>
                  Your Order have been cancel, Your Order Id {id?.split("-")[1]}{" "}
                  .
                </p>
                ss
                <Link to={"/MyOrder"} class="btn btn-outline-danger">
                  My Orders
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CancelOrder;

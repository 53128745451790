import React from "react";
import creat from "../images/create-account.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { SendOtpApi } from "../helper/integration.";
import { useCookies } from "react-cookie";

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

function generateString(length) {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
const VerifyEmail = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [cookie, setCookies] = useCookies(["email-verify"]);
  const navigate = useNavigate();
  const handleSendOtp = ({ email }) => {
    let newCode = generateString(10);
    toast
      .promise(SendOtpApi({ email, code: newCode }), {
        loading: "Wait Few Moment !",
        success: "Change Your Email For Verification ! ",
        error: (err) => err?.message || "Something Wrong !",
      })
      .then((val) => {
        reset();
        setCookies("email-verify", newCode);
      })
      .catch(() => {
        navigate("/Sign-in");
      });
  };
  return (
    <div>
      <div className="bg-create">
        <img src={creat} />
      </div>
      <div className="container pb-5">
        <div className="row spl-center top-form">
          <div className="col-lg-8 col-md-8 col-12">
            <form className="white-bg" onSubmit={handleSubmit(handleSendOtp)}>
              <div className="createtxt py-4">
                <h2>Send Otp for Forget Password !</h2>
                {/* <p>

                </p> */}
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label frn-label"
                  >
                    Email address <span>**</span>
                  </label>
                  <input
                    type="email"
                    className="form-control frn-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    {...register("email", { required: true })}
                  />
                  {errors?.email && (
                    <p className="pt-3 px-3 text-danger ">
                      Please Enter Email !
                    </p>
                  )}
                </div>
              </div>

              {/* <div className="d-flex align-items-center justify-content-between py-3">
                <div className="form-check check-text"></div>
              </div> */}
              <div className="col-12 w-100 mt-md-5 mt-4">
                <button type="submit" className="regi-btn w-100 ">
                  Send OTP
                </button>
              </div>
              <div className="col-12 mt-3 mb-3">
                <div className="or-txt">
                  <hr />
                  <h3>or</h3>
                  <hr />
                </div>
              </div>
              <div className="col-12">
                <Link to={"/"} className="regi-btn-to">
                  Back To Login ?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;

import React, { useState } from "react";
import toast from "react-hot-toast";
import { POSTAPI } from "../../helper/integration.";
import { ApiObj } from "../../utils/Api";
import { Link } from "react-router-dom";

const SendMail = () => {
  const [email, setemail] = useState("");
  const handleSendMail = () => {
    toast
      .promise(POSTAPI(ApiObj.GetTouch, { email }), {
        loading: "Wait Few Moment !",
        success: "Thank You For Contact , We Will Be Touch Soon !",
        error: "Something Wrong !",
      })
      .then(() => {
        setemail("");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="container p-main-80 pt-0">
      <div className="subcribe-bg">
        <div className="row spl-cen">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="subcribe-txt">
              <h2>Sign Up to Get Updates & News About Us.</h2>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="subcribe-txt">
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
              <Link onClick={handleSendMail}>Subscribe</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMail;

import React, { useEffect } from "react";
import creat from "../images/create-account.svg";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ChangePasswordApi } from "../helper/integration.";
import { MdReportGmailerrorred } from "react-icons/md";
const ForgatePassword = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const [cookie, setCookies, removeCookie] = useCookies(["email-verify"]);

  const handleChangePassword = (data) => {
    if (data.password != data.cpassword) {
      return toast.error("Password is not match !");
    }
    const passwordRegex = /^(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;

    if (!passwordRegex.test(data.password)) {
      return toast.error(
        "Please enter a valid password with at least 8 characters and containing at least one special character"
      );
    }
    toast
      .promise(
        ChangePasswordApi({
          id: id?.split("-")[1],
          password: data.password,
        }),
        {
          loading: "Wait Few Moment !",
          success: "Password Change Successfully And Continue Login  !",
          error: "Password Not Change !",
        }
      )
      .then((val) => {
        reset();
        removeCookie("email-verify");
        return <Navigate to={"/Sign-in"} replace={true} />;
      })
      .catch(() => {
        reset();
      });
  };
  useEffect(() => {
    if (
      !id ||
      !cookie?.["email-verify"] ||
      id.split("-")[0]?.trim() != cookie["email-verify"]?.trim()
    ) {
      document.getElementById("modalOpen").click();
    }
  }, []);
  return (
    <div>
      <div className="bg-create">
        <img src={creat} />
        <Link
          id="modalOpen"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop2"
          hidden
        ></Link>
      </div>
      {/* Modal */}
      <div
        class="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class=" fs-5" id="staticBackdropLabel">
                Warning
              </h1>
            </div>
            <div class="modal-body w-100 ">
              <div className="d-flex justify-content-center  align-items-center  flex-column ">
                <MdReportGmailerrorred size={100} color="F73131" />
                <p
                  style={{ fontSize: "15px" }}
                  align="center"
                  className="text-capitalize "
                >
                  Unauthorized Password Recovery Access: Please note that you
                  are not authorized to initiate password recovery from this
                  browser. To reset your password, kindly open the{" "}
                  <span
                    className="text-primary "
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      await navigator.clipboard.writeText(window.location.href);
                      toast.success("Copy Successfully !");
                    }}
                  >
                    following link
                  </span>{" "}
                  using the designated browser. Only requests made through the
                  specified browser will be processed. Thank you for your
                  cooperation.
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                id="closeWarn1"
                hidden
              >
                Close
              </button>
              <Link
                onClick={() => document.getElementById("closeWarn1").click()}
                to={"/Sign-in"}
                class="btn btn-danger"
              >
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* End  Modal */}
      <div className="container pb-5">
        <div className="row spl-center top-form">
          <div className="col-lg-8 col-md-8 col-12">
            <form
              onSubmit={handleSubmit(handleChangePassword)}
              className="white-bg"
            >
              <div className="createtxt">
                <h2>Create New Password !</h2>
                {/* <p>

                </p> */}
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label frn-label"
                  >
                    New Password <span>**</span>
                  </label>
                  <input
                    type="password"
                    className="form-control frn-control"
                    id="exampleFormControlInput1"
                    placeholder="New Passsword"
                    {...register("password", { required: true })}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label frn-label"
                  >
                    Confirm Password <span>**</span>
                  </label>
                  <input
                    type="password"
                    className="form-control frn-control"
                    id="exampleInputPassword1"
                    placeholder="Confirm  password"
                    {...register("cpassword", { required: true })}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between py-3">
                <div className="form-check check-text"></div>
              </div>
              <div className="col-12 w-100 ">
                <button type="submit" className="regi-btn w-100 ">
                  Create New Password
                </button>
              </div>
              <div className="col-12 mt-3 mb-3">
                <div className="or-txt">
                  <hr />
                  <h3>or</h3>
                  <hr />
                </div>
              </div>
              <div className="col-12">
                <Link to={"/"} className="regi-btn-to">
                  Back To Login ?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgatePassword;

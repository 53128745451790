import React, { useEffect } from "react";
import creat from "../images/create-account.svg"; // with import
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { LoginApi } from "../helper/integration.";
import { useCookies } from "react-cookie";
const LoginPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [cookie, setCookies] = useCookies(["user"]);
  const navigate = useNavigate();
  if (cookie?.user) return <Navigate to={"/Home"} replace={true} />;
  const handleLoginUser = (data) => {
    toast
      .promise(LoginApi(data), {
        loading: "Wait Few Moment !",
        success: "Login Successfully !",
        error: "Email or Password is wrong !",
      })
      .then((val) => {
        setCookies("user", val.data.id);
        reset();
        navigate("/");
      })
      .catch(() => {});
  };
  return (
    <div>
      <div className="bg-create">
        <img src={creat} />
      </div>
      <div className="container pb-5">
        <div className="row spl-center top-form">
          <div className="col-lg-8 col-md-8 col-12">
            <form onSubmit={handleSubmit(handleLoginUser)} className="white-bg">
              <div className="createtxt">
                <h2>Login From Here</h2>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium
                </p>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label frn-label"
                  >
                    Email address <span>**</span>
                  </label>
                  <input
                    type="email"
                    className="form-control frn-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    {...register("email", { required: true })}
                  />
                </div>
                {errors?.email && (
                  <p className="p-2 text-danger ">Please Enter Email !</p>
                )}
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label frn-label"
                  >
                    Password <span>**</span>
                  </label>
                  <input
                    type="password"
                    className="form-control frn-control"
                    id="exampleInputPassword1"
                    placeholder="Enter password"
                    {...register("password", { required: true })}
                  />
                  {errors?.password && (
                    <p className="p-2 text-danger ">Please Enter Password !</p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between py-3">
                <div className="form-check check-text">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    {...register("check")}
                  />
                  <label className="form-check-label" for="gridCheck">
                    Remember me!
                  </label>
                </div>
                <div className="forgot-pass">
                  <Link to={"/Send-otp"}>Forget your password?</Link>
                </div>
              </div>
              <div className="col-12 w-100 ">
                <button type="submit" className="regi-btn w-100 ">
                  {" "}
                  Login now
                </button>
              </div>
              <div className="col-12 mt-3 mb-3">
                <div className="or-txt">
                  <hr />
                  <h3>or</h3>
                  <hr />
                </div>
              </div>
              <div className="col-12">
                <Link to={"/CreateAccount"} className="regi-btn-to">
                  Create account
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

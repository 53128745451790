import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import firstimghome from "../images/homefirstimg.svg";
import truckicon from "../images/truckicon.svg";
import cashicon from "../images/cashicon.svg";
import easyicon from "../images/easyicon.svg";

import HomeSlidertwo from "./HomeSlidertwo";
import HomeOrganicSlider from "./HomeOrganicSlider";

import PopulerProduct from "../common/Home/PopulerProduct";
import SliderPopuler from "../common/Home/SliderPopuler";
import SendMail from "../common/Home/SendMail";
import Scrolltotop from "../Scrolltotop";

// import homeslidertwofirst from "../images/homeslidertwo-one.svg";

const Home = () => {
  return (
    <div>
      {<Header />}
      <div className="homepage-bg py-main-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 col-12">
              <div className="home-first-sec-txt">
                <h2>URVI BAZZAR</h2>
                <h3>Cosima Channel Sweets</h3>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium
                </p>
                <Link to={"/Shop"}>
                  SHOP NOW<i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-12">
              <div className="home-img">

                <img src={firstimghome} className="img-fluid" alt="first"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row home-up-margin ">
          <div className="col-lg-4 col-sm-6 col-md-6 mb-3">
            <div className="whitebox-home">
              <div className="whiteicon-box">
                <img src={truckicon} className="img-fluid" alt="first"></img>
              </div>
              <div className="whitebox-txt">
                <h2>Free Shipping</h2>
                <p>Capped at $39 per order</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-md-6 mb-3">
            <div className="whitebox-home">
              <div className="whiteicon-box">
                <img src={cashicon} className="img-fluid" alt="first"></img>
              </div>
              <div className="whitebox-txt">
                <h2>Card Payments</h2>
                <p>12 Months Installments</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-md-6 mb-3">
            <div className="whitebox-home">
              <div className="whiteicon-box">
                <img src={easyicon} className="img-fluid" alt="first"></img>
              </div>
              <div className="whitebox-txt">
                <h2>Easy Returns</h2>
                <p>Shop With Confidence</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">{<HomeOrganicSlider />}</div>
      <PopulerProduct />
      <SliderPopuler />
      <section className="p-main-80">
        <div className="container">
          <div className="home-heading">
            <h2>Our Customer Feedback</h2>
          </div>
          <HomeSlidertwo />
        </div>
      </section>
      <SendMail />
      {<Footer />}
    </div>
  );
};

export default Home;

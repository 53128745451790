import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function HomeSlidertwo() {
  const settings = {
    // dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 200,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 524,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container p-main-80 pb-0">
      <Slider {...settings} className="d-flex ">
        <div className="container px-md-2 px-1">
          <div className="organic-product">
            <h2>20% Most Save Product</h2>
            <h3>Gifts & others</h3>
            <Link to={"/Shop"}>
              Shop Now <i class="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="container px-md-2 px-1">
          <div className="organic-product organic-product-two">
            <h2>20% Most Save Product</h2>
            <h3>Women dresses</h3>
            <Link to={"/Shop"}>
              Shop Now <i class="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="container px-md-2 px-1">
          <div className="organic-product organic-product-three">
            <h2>20% Most Save Product</h2>
            <h3>Beauty products</h3>
            <Link to={"/Shop"}>
              Shop Now <i class="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="container px-md-2 px-1">
          <div className="organic-product organic-product-four">
            <h2>20% Most Save Product</h2>
            <h3>Organic Sweets</h3>
            <Link to={"/Shop"}>
              Shop Now <i class="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default HomeSlidertwo;

import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import productone from "../images/productone.svg";

import { Link, Navigate } from "react-router-dom";
import useGetOrders from "../hooks/useGetOrders";
import NotFound from "../images/not_found.svg";
import { loadStripe } from "@stripe/stripe-js";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";
import { useCookies } from "react-cookie";

const GetDate = (dateString) => {
  let date = new Date(dateString);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const MyOrder = () => {
  const { isLoad, Orders } = useGetOrders();
  const [Loader, setLoader] = useState(false);
  const [cookie] = useCookies(["user"]);
  if (!cookie.user) return <Navigate to={"/"} replace={true} />;
  const handleCompletePayment = async (id) => {
    try {
      setLoader(true);
      const stripe = await loadStripe(
        "pk_test_51OcVUVBmPUIefef4OpbJzVTpgOXCculXBBI3fnJqFp5SKQkVi0OADCiP7WK1QIVKY0GC5sN3adB4ZXtq5ahp6NZ9003KQUTOaH"
      );
      const data = await POSTAPI(ApiObj.PendingPayment, { id });
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });
      setLoader(false);
    } catch (er) {
      setLoader(false);
    }
  };
  return (
    <div>
      <Header />
      {Loader && (
        <div className="payment-loader">
          <div className="d-flex justify-content-center  align-items-center  h-100">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <div className="aboutfirst mt-103">
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>My Orders
        </p>
      </div>
      <div className="container p-main-80">
        <div className="responsive-table">
          <table class="table">
            <thead>
              <tr className="table-head">
                <th scope="col">Product</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th>
                <th scope="col">Total</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!isLoad ? (
                <tr>
                  <td colSpan={5}>
                    <div className="d-flex justify-content-center my-3  align-items-center w-100 flex-column ">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              ) : !Orders?.length ? (
                <tr>
                  <td colSpan={5}>
                    <div className="d-flex justify-content-center  align-items-center w-100 flex-column ">
                      <img src={NotFound} className="w-25 h-25" />
                      <p>MyOrder Not Found !</p>
                    </div>
                  </td>
                </tr>
              ) : (
                Orders.map((item) => {
                  console.log(item);
                  return (
                    <tr className="table-data">
                      <td>
                        <div className="table-pro-name">
                          <img
                            src={item?.image}
                            onError={(e) => {
                              e.currentTarget.src = productone;
                            }}
                            alt=""
                            loading="lazy"
                          />
                          <div>
                            <Link to={`/OrderDetail/${item.order_id}`}>
                              #{item?.order_id}
                            </Link>
                            <p>{item?.name}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="tbl-pro-price">
                          <p>{GetDate(item?.created_at)}</p>
                        </div>
                      </td>
                      <td>
                        {item?.payment_status != "Complete" ? (
                          <>
                            <div className="cancell-order-tbl">
                              <p>
                                <i class="fa-solid fa-circle"></i> Payment is
                                incomplete
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            {item?.order_status == "Complete" && (
                              <div className="complete-order-tbl">
                                <p>
                                  <i class="fa-solid fa-circle"></i> completed
                                </p>
                                <h6>
                                  ( Order Deliver On{" "}
                                  <span>{item?.deliver_date}</span> )
                                </h6>
                              </div>
                            )}
                            {item?.order_status == "Pending" && (
                              <div className="pending-order-tbl">
                                <p>
                                  <i class="fa-solid fa-circle"></i> Pending
                                </p>
                              </div>
                            )}
                            {item?.order_status == "On Going" && (
                              <div className="processing-order-tbl">
                                <p>
                                  <i class="fa-solid fa-circle"></i> On Going
                                </p>
                              </div>
                            )}
                            {item?.order_status == "Cancel" && (
                              <div className="cancell-order-tbl">
                                <p>
                                  <i class="fa-solid fa-circle"></i> Order
                                  Cancelled
                                </p>
                                <Link to={`/OrderDetail/${item.order_id}`}>
                                  SEE WHY ?
                                </Link>
                              </div>
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        <div className="tbl-pro-price">
                          <p>
                            $ {item?.total_price}
                            <span>( {item?.total_quantity} item )</span>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="tbl-sec-txt">
                          {item?.payment_status != "Complete" ? (
                            item?.payment_status == "Pending" ? (
                              <Link
                                type="button"
                                onClick={() => handleCompletePayment(item.id)}
                              >
                                Complete Your Payment{" "}
                              </Link>
                            ) : (
                              <p className="text-danger mb-0">
                                Your order is canceled !
                              </p>
                            )
                          ) : (
                            <Link to={`/OrderDetail/${item.order_id}`}>
                              VIEW DETAILS
                            </Link>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
              {/* 
              <tr className="table-data">
                <td>
                  <div className="table-pro-name">
                    <img src={productone} alt="" />
                    <div>
                      <p>#24586</p>
                      <p>Cheery</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="tbl-pro-price">
                    <p>20 August 2018</p>
                  </div>
                </td>
                <td>
                  <div className="processing-order-tbl">
                    <p>
                      <i class="fa-solid fa-circle"></i> Processing
                    </p>
                    <h6>
                      ( Order Deliver On <span>22th March</span>{" "}
                      <span>06:00 AM</span> )
                    </h6>
                  </div>
                </td>
                <td>
                  <div className="tbl-pro-price">
                    <p>
                      $ 18.00 <span>( 1 item )</span>
                    </p>
                  </div>
                </td>
                <td>
                  <div className="tbl-sec-txt">
                    <Link to={"/OrderDetail"}>VIEW DETAILS</Link>
                  </div>
                </td>
              </tr>
              <tr className="table-data">
                <td>
                  <div className="table-pro-name">
                    <img src={productone} alt="" />
                    <div>
                      <p>#24586</p>
                      <p>Cheery</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="tbl-pro-price">
                    <p>20 August 2018</p>
                  </div>
                </td>
                <td>
                  <div className="cancell-order-tbl">
                    <p>
                      <i class="fa-solid fa-circle"></i> Order Cancelled
                    </p>
                    <Link to={"#"}>SEE WHY ?</Link>
                  </div>
                </td>
                <td>
                  <div className="tbl-pro-price">
                    <p>
                      $ 18.00 <span>( 1 item )</span>
                    </p>
                  </div>
                </td>
                <td>
                  <div className="tbl-sec-txt">
                    <Link to={"/OrderDetail"}>VIEW DETAILS</Link>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyOrder;

import React, { useState } from "react";
import HomeSlider from "../../Components/HomeSlider";
import useGetFilter from "../../hooks/useGetFilter";

const SliderPopuler = () => {
  const { Filters } = useGetFilter();
  const [SelectFiter, setSelectFiter] = useState(null);
  return (
    <section className="p-main-80 homepage-bg ">
      <div className="container">
        <div className="menuflx">
          <div className="home-heading d-flex ">
            <h2 className="text-white mb-lg-0">Our Popular Products</h2>
          </div>
          <div className="second-slider-tab-design-to">
            <ul
              className="nav nav-pills slider-nav-pills "
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => setSelectFiter(null)}
                  className="nav-link active"
                  id="pills-home-all-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home-all"
                  type="button"
                  role="tab"
                  aria-controls="pills-home-all"
                  aria-selected="true"
                >
                  All
                </button>
              </li>
              {Filters?.Categories?.map((item) => {
                return (
                  <li className="nav-item" role="presentation">
                    <button
                      onClick={() => setSelectFiter(item?.id)}
                      className="nav-link  text-capitalize "
                      id="pills-home-all-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home-all"
                      type="button"
                      role="tab"
                      aria-controls="pills-home-all"
                      aria-selected="true"
                    >
                      {item?.name?.replace("_", " ")}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-12">
          <HomeSlider Filter={SelectFiter} />
        </div>
      </div>
    </section>
  );
};

export default SliderPopuler;

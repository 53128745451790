import React from "react";
import { Link } from "react-router-dom";

const Paggination = ({ currentPage, nPage, handleChangePage }) => {
  return (
    <nav aria-label="...">
      <ul className="pagination">
        <li
          className={` page-item ${currentPage == 1 && "disactive"} page-btn`}
        >
          <button
            className="bg-transparent border-0  page-button "
            type="button"
            disabled={currentPage == 1}
            onClick={() => handleChangePage(currentPage - 1)}
          >
            <i class="fa-solid fa-chevron-left"></i>
          </button>
        </li>
        {nPage?.map((item) => {
          return (
            <li
              className={`page-item ${currentPage == item && "active"}`}
              onClick={() => handleChangePage(item)}
            >
              <Link>{item}</Link>
            </li>
          );
        })}

        <li
          className={` page-item ${
            nPage?.length == currentPage && "disactive"
          } page-btn`}
        >
          <button
            className="bg-transparent border-0  page-button "
            disabled={nPage?.length == currentPage}
            onClick={() => handleChangePage(currentPage + 1)}
          >
            <i class="fa-solid fa-chevron-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Paggination;

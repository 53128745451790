import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import productone from "../images/productone.svg";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCartsDetail,
  AddQuantity,
  CartsDetail,
  RemoveCarts,
  RemoveQuantity,
} from "../redux/userSlice";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import NotFound from "../images/not_found.svg";
import { CiWarning } from "react-icons/ci";
import { MdReportGmailerrorred } from "react-icons/md";
const Cart = () => {
  const [isLoad, setisLoad] = useState(false);
  const [ProductId, setProductId] = useState("");
  const dispatch = useDispatch();
  const [cookie] = useCookies(["user"]);
  const UserCartDetail = useSelector(CartsDetail);

  const handleChangeQuantity = async (obj) => {
    try {
      await POSTAPI(ApiObj.ChangeQuantity, obj);
    } catch (er) {
      console.log(er);
    }
  };
  const handleGetTotal = (data) => {
    let total = 0;
    data.forEach((element) => {
      total += Number(element.price) * Number(element.quantity);
    });
    return total;
  };
  const handleRemoveCart = (id) => {
    let obj = {
      user_id: cookie.user,
      product_id: id,
    };
    toast
      .promise(POSTAPI(ApiObj.RemoveCart, obj), {
        loading: "Wait Few Moment !",
        success: "Cart Remove Successfully !",
        error: "Somwthig Wrong !",
      })
      .then(() => {
        dispatch(RemoveCarts(obj));
      })
      .catch(() => { });
    document.getElementById("closeCart1").click();
  };
  useEffect(() => {
    (async () => {
      try {
        if (!cookie.user) return <Navigate to={"/"} replace={true} />;
        setisLoad(false);
        const data = await POSTAPI(ApiObj.ShowCart, { user_id: cookie.user });
        dispatch(AddCartsDetail(data.data));
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, []);

  return (
    <div>
      <Header />
      <div className="aboutfirst mt-103">
        {/* Modal */}

        {/* End Modal */}
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>Cart
        </p>
      </div>
      <div className="container p-main-80">
        <div className="responsive-table">
          <table class="table">
            <thead>
              <tr className="table-head">
                <th scope="col">PRODUCT</th>
                <th scope="col">PRICE</th>
                <th scope="col">QUANTITY</th>
                <th scope="col">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {!isLoad ? (
                <tr>
                  <td colSpan={4}>
                    <div className="d-flex  flex-column my-4 justify-content-center  align-items-center my-2">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              ) : !UserCartDetail?.length ? (
                <tr>
                  <td colSpan={4}>
                    <div className="d-flex justify-content-center flex-column  align-items-center w-100">
                      <img src={NotFound} className="w-25 h-25" />
                      <p>Cart Not Found ! </p>
                    </div>
                  </td>
                </tr>
              ) : (
                UserCartDetail?.map((item) => {
                  return (
                    <tr className="table-data">
                      <td>
                        <div className="table-pro-name">
                          <img
                            src={item?.image}
                            onError={(e) => (e.currentTarget.src = productone)}
                            alt=""
                          />
                          <p>{item?.name}</p>
                        </div>
                      </td>
                      <td>
                        <div className="tbl-pro-price">
                          <p>$ {item?.price}</p>
                        </div>
                      </td>
                      <td>
                        <div className="framee">
                          <div className="containerr">
                            <div className="button-plus-minus">
                              <button
                                className="border-0 bg-transparent"
                                id="minus-btn"
                                onClick={() => {
                                  if (item?.quantity > 1) {
                                    dispatch(RemoveQuantity(item?.id));
                                    handleChangeQuantity({
                                      id: item?.cart_id,
                                      type: "des",
                                    });
                                  }
                                }}
                              >
                                -
                              </button>
                            </div>
                            <div className="number">
                              <p id="count">{item?.quantity}</p>
                            </div>
                            <div className="button-plus-minus">
                              <button
                                className="border-0 bg-transparent"
                                id="plus-btn"
                                onClick={() => {
                                  if (item?.stock_quantity > item?.quantity) {
                                    dispatch(AddQuantity(item?.id));
                                    handleChangeQuantity({
                                      id: item?.cart_id,
                                      type: "inc",
                                    });
                                  } else {
                                    toast("Sorry Stock Is Limited ! ", {
                                      icon: (
                                        <CiWarning size={20} color="#F4D50B" />
                                      ),
                                    });
                                  }
                                }}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="tbl-shop-now">
                          <div className="tbl-pro-price">
                            <p>
                              ${" "}
                              {(
                                Number(item?.price) * Number(item?.quantity)
                              ).toFixed(2)}
                            </p>
                          </div>
                          <div className="cancle-tbl">
                            <Link
                              onClick={() => setProductId(item.id)}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop2"
                            >
                              <i class="fa-solid fa-xmark"></i>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="three-btn">
          <div className="two-btn-tbl">
            <div className="input-group search-shop w-auto me-3">
              <input
                type="text"
                className="form-control px-3"
                placeholder="Coupon Code"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
            </div>
            <div className="tbl-sec-txt">
              <Link className="con-shoping">apply coupon</Link>
            </div>
          </div>
        </div> */}
      </div>
      {UserCartDetail?.length && (
        <div className="container p-main-80 pt-0">
          <div className="cart-totals">
            <h3>CART TOTALS</h3>
            <div className="total-subtotal">
              <div className="subtotal">
                <p>Subtotal</p>
                <h4>${handleGetTotal(UserCartDetail).toFixed(2)}</h4>
              </div>
              <div className="subtotal">
                <p>Total</p>
                <h4 className="text-urvi">
                  ${handleGetTotal(UserCartDetail).toFixed(2)}
                </h4>
              </div>
            </div>
            <div className="tbl-sec-txt">
              <Link to={"/PaymentOption"} className="con-shoping">
                proceed to checkout
              </Link>
            </div>
          </div>
          {/* Modal */}
          <div
            class="modal fade"
            id="staticBackdrop2"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class=" fs-5" id="staticBackdropLabel">
                    Remove Cart
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body w-100 ">
                  <div className="d-flex justify-content-center  align-items-center  flex-column ">
                    <MdReportGmailerrorred size={100} color="F73131" />
                    <p
                      style={{ fontSize: "20px" }}
                      className="text-capitalize "
                    >
                      are you sure you want to remove ?
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    id="closeCart1"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={() => handleRemoveCart(ProductId)}
                  >
                    Remove Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End Modal */}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Cart;

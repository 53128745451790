import React, { useEffect, useState } from "react";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";

const useGetFilterProduct = () => {
  const [isProductLoad, setisProductLoad] = useState(false);
  const [Products, setProducts] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const data = await POSTAPI(ApiObj.ShowFilterProduct);
        setProducts(data.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisProductLoad(true);
      }
    })();
  }, []);
  return { isProductLoad, Products };
};

export default useGetFilterProduct;

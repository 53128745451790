import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import paypal from "../images/paypal.svg";
import { Link } from "react-router-dom";
import { AddCartsDetail, CartsDetail } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";
import { useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js";
import toast from "react-hot-toast";
import useCoutry from "../hooks/useCoutry";
const CheckOut = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [coupon, setcoupon] = useState(null);
  const [discount, setdiscount] = useState("");
  const CartDetail = useSelector(CartsDetail);
  const dispatch = useDispatch();
  const [cookie, setCookies, removeCookies] = useCookies(["user"]);
  const country = useCoutry();
  const [Loading, setLoading] = useState(false);
  const [code, setcode] = useState("");
  const handleGetCoupn = async () => {
    try {
      if (code) {
        const data = await toast.promise(POSTAPI(ApiObj.ShowCoupon, { code }), {
          loading: "Wait Few Moment !",
          success: "Coupon Code Apply Successfully !",
          error: (err) => err.message,
        });
        setcoupon(data.data);
        setdiscount(data.data.discount);
      } else {
        return toast.error("Please Enter Coupon Code !");
      }
    } catch (er) { }
  };
  const handleGetTotal = (data) => {
    let total = 0;
    data.forEach((element) => {
      total += Number(element.price) * Number(element.quantity);
    });
    return total;
  };

  const handleOrder = async (formdata) => {
    try {
      setLoading(true);
      const stripe = await loadStripe(
        "pk_test_51OcVUVBmPUIefef4OpbJzVTpgOXCculXBBI3fnJqFp5SKQkVi0OADCiP7WK1QIVKY0GC5sN3adB4ZXtq5ahp6NZ9003KQUTOaH"
      );
      const newCart = CartDetail?.map((item) => {
        return {
          product_id: item?.id,
          quantity: item?.quantity,
        };
      });
      let total_price = coupon
        ? handleGetTotal(CartDetail) -
        (handleGetTotal(CartDetail) * coupon.discount) / 100
        : handleGetTotal(CartDetail);
      let obj = {
        user_id: cookie.user,
        ...formdata,
        coupone_code: code || "",
        cart_detail: newCart,
        total_price: Math.ceil(total_price),
      };

      const data = await POSTAPI(ApiObj.StripePayment, obj);
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });
    } catch (er) {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (CartDetail?.length) return;
        const data = await POSTAPI(ApiObj.ShowCart, { user_id: cookie.user });
        dispatch(AddCartsDetail(data.data));
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);
  return (
    <div>
      {Loading && (
        <div className="payment-loader">
          <div className="d-flex justify-content-center  align-items-center  h-100">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <Header />
      <div className="aboutfirst mt-103">
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>Place Order
        </p>
      </div>
      <div className="container p-main-80">
        {/* <div className="payment-link-option">
          <p>
            Returning New customer Account ?{" "}
            <Link onClick={() => removeCookies("user")} to={"/"}>
              Click here to login{" "}
            </Link>
          </p>
        </div> */}
        <form
          onSubmit={handleSubmit(handleOrder)}
          className="row"
        >
          <div className="col-lg-8 col-md-6 col-12 ">
            <div className="product-form">
              <h3 className="mt-0">Billing details</h3>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 ">
                <label for="firstname" class="form-label p-label">
                  First Name <span>*</span>
                </label>

                <input
                  type="text"
                  className={`form-control p-control ${errors?.f_name ? null : "mb-3"
                    }`}
                  placeholder="First name"
                  aria-label="First name"
                  {...register("f_name", { required: true })}
                />
                {errors?.f_name && (
                  <p className="text-danger  px-1">Please Enter First Name !</p>
                )}
              </div>
              <div className="col-lg-6 col-md-12 ">
                <label for="lastname" className="form-label p-label">
                  Last Name <span>*</span>
                </label>
                <input
                  type="text"
                  className={`form-control p-control ${errors?.l_name ? null : "mb-3"
                    }`}
                  placeholder="First name"
                  aria-label="Last name"
                  {...register("l_name", { required: true })}
                />
                {errors?.l_name && (
                  <p className="text-danger  px-1">Please Enter Last Name !</p>
                )}
              </div>
              <div className="col-12 ">
                <label for="companyname" className="form-label p-label">
                  Company Name <span>*</span>
                </label>
                <input
                  type="text"
                  className={`form-control p-control ${errors?.compony_name ? null : "mb-3"
                    }`}
                  placeholder="Company Name"
                  aria-label="Company name"
                  {...register("compony_name", { required: true })}
                />
                {errors?.compony_name && (
                  <p className="text-danger  px-1">
                    Please Enter Compony Name !
                  </p>
                )}
              </div>
              <div className="col-12 ">
                <label for="companyname" className="form-label p-label">
                  Country <span>*</span>
                </label>
                <select
                  class="form-select p-control p-control-to mb-3"
                  aria-label="Default select example"
                  {...register("country")}
                >
                  {country?.map((item) => {
                    return <option value={item?.name}>{item?.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-12 ">
                <label for="Address" className="form-label p-label">
                  Address <span>*</span>
                </label>
                <input
                  type="text"
                  className={`form-control p-control ${errors?.address ? null : "mb-3"
                    }`}
                  placeholder="Street address"
                  aria-label="Address"
                  {...register("address", { required: true })}
                />
                {errors?.address && (
                  <p className="text-danger  px-1">Please Enter Address !</p>
                )}
                <label for="Address" class="form-label p-label">
                  Apartment <span>*</span>
                </label>
                <input
                  type="text"
                  className={`form-control p-control ${errors?.apartment ? null : "mb-3"
                    } `}
                  placeholder="Apartment, suite, unit etc. (optional)"
                  aria-label="Address"
                  {...register("apartment", { required: true })}
                />
                {errors?.apartment && (
                  <p className="text-danger  px-1">Please Enter Apartment !</p>
                )}
              </div>
              <div className="col-12 ">
                <label for="Town/City" class="form-label p-label">
                  Town/City <span>*</span>
                </label>
                <input
                  type="text"
                  className={`form-control p-control ${errors?.city ? null : "mb-3"
                    }`}
                  placeholder="Town/City"
                  aria-label="Town/City "
                  {...register("city", { required: true })}
                />
                {errors?.city && (
                  <p className="text-danger  px-1">Please Enter City !</p>
                )}
              </div>
              <div className="col-12 ">
                <label for="Postcode / Zip" className="form-label p-label">
                  Postcode / Zip <span>*</span>
                </label>
                <input
                  type="number"
                  className={`form-control p-control ${errors?.zip ? null : "mb-3"
                    }`}
                  placeholder="Postcode / Zip"
                  aria-label="Postcode / Zip "
                  {...register("zip", { required: true })}
                />
                {errors?.zip && (
                  <p className="text-danger  px-1">Please Enter Zip Code !</p>
                )}
              </div>
              <div className="col-lg-6 col-md-12 ">
                <label for="Phone " class="form-label p-label">
                  Phone <span>*</span>
                </label>
                <input
                  type="number"
                  className={`form-control p-control ${errors?.phone ? null : "mb-3"
                    }`}
                  placeholder="Phone "
                  aria-label="Phone  "
                  {...register("phone", { required: true })}
                />
                {errors?.phone && (
                  <p className="text-danger  px-1">
                    Please Enter Phone Number !
                  </p>
                )}
              </div>
              <div className="col-lg-6 col-md-12 ">
                <label
                  for="exampleFormControlInput1"
                  className="form-label p-label"
                >
                  Email address<span>*</span>
                </label>
                <input
                  type="email"
                  className={`form-control p-control ${errors?.email ? null : "mb-3"
                    }`}
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                  {...register("email", { required: true })}
                />
                {errors?.email && (
                  <p className="text-danger  px-1">Please Enter Email !</p>
                )}
              </div>
              <div className="col-12 ">
                <div className="form-check p-check">
                  <input
                    className="form-check-input p-label"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    {...register("condition", { required: true })}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    I agree Terms & Condition
                  </label>
                </div>
                {errors?.condition && (
                  <p className="text-danger  px-1">Please Read And Agree !</p>
                )}
              </div>
              <div className="product-form">
                <h3>Additional information</h3>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label
                    for="exampleFormControlTextarea1"
                    className="form-label p-label"
                  >
                    Order notes
                  </label>
                  <textarea
                    className={`form-control p-control mb-3`}
                    id="exampleFormControlTextarea1"
                    rows="8"
                    placeholder="Note about your order, eg. special notes fordelivery."
                    {...register("additional_info")}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="prodcuct-sec-two">
              <h3>Your order</h3>
              <div className="product-total pt-3">
                <h4>Product</h4>
                <h4>Total</h4>
              </div>
              <hr />
              {CartDetail?.map((item) => {
                return (
                  <div className="product-total pt-3">
                    <h4>
                      {item?.name} *{item?.quantity}
                    </h4>
                    <h4>{item?.price * item?.quantity}</h4>
                  </div>
                );
              })}
              <hr />
              <div className="two-btn-tbl my-4">
                <div className="input-group search-shop w-auto me-3">
                  <input
                    type="text"
                    className="form-control px-3"
                    placeholder="Coupon Code"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    readOnly={!!coupon}
                    value={code}
                    onChange={(e) => setcode(e.target.value)}
                  />
                </div>
                <div className="tbl-sec-txt">
                  <button
                    type="button"
                    onClick={handleGetCoupn}
                    className="con-shoping"
                    disabled={!!coupon}
                  >
                    apply coupon
                  </button>
                </div>
              </div>
              <div className="product-total-to pt-3">
                <h3>Subtotal</h3>
                <h4>${handleGetTotal(CartDetail)}</h4>
              </div>
              <hr />
              {coupon && (
                <>
                  <div className="product-total-to pt-3">
                    <h3>
                      {coupon.name} {coupon.discount} %{" "}
                    </h3>
                    <h4>
                      $
                      {(
                        (handleGetTotal(CartDetail) * coupon.discount) /
                        100
                      ).toFixed(2)}
                    </h4>
                  </div>
                  <hr />
                </>
              )}
              <div className="product-total-to">
                <h5>Total</h5>
                <h6>
                  $
                  {coupon
                    ? (
                      handleGetTotal(CartDetail) -
                      (handleGetTotal(CartDetail) * coupon.discount) / 100
                    ).toFixed(2)
                    : handleGetTotal(CartDetail)}
                </h6>
              </div>

              <div className="pro-section-payment">
                <div className="p-15-20 what-is-paypal">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked
                    />
                    <label
                      class="form-check-label ff-label"
                      for="flexRadioDefault2"
                    >
                      Paypal
                    </label>
                  </div>
                  <img
                    src={paypal}
                    alt="paypal"
                    className="ms-3 mt-3 mb-3"
                  ></img>
                  <p>
                    <Link to={"#"} className="ms-3">
                      What is paypal?
                    </Link>
                  </p>
                </div>
              </div>
              <div className="place-oreder w-100 ">
                <button onClick={handleOrder} className="w-100">
                  Place order
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default CheckOut;

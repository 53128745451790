import React from "react";
import creat from "../images/create-account.svg"; // with import
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RegisterApi } from "../helper/integration.";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
const CreateAccount = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies(["user"]);

  const handleRegisterUser = (data) => {
    toast
      .promise(RegisterApi(data), {
        loading: "Wait Few Moment ",
        success: "Register Successfully !",
        error: (err) => err.message,
      })
      .then((val) => {
        setCookies("user", val.id);
        navigate("/");
        reset();
      })
      .catch((er) => {
        console.log(er);
      });
  };
  return (
    <div>
      <div className="bg-create">
        <img src={creat} />
      </div>
      <div className="container pb-5">
        <div className="row spl-center top-form">
          <div className="col-lg-8 col-md-8 col-12">
            <form
              onSubmit={handleSubmit(handleRegisterUser)}
              className="white-bg"
            >
              <div className="createtxt">
                <h2>Create Account</h2>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium
                </p>
              </div>
              <div className="col-12 ">
                <div className="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label frn-label"
                  >
                    Username <span>**</span>
                  </label>
                  <input
                    type="text"
                    className="form-control frn-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter Username..."
                    {...register("username", { required: true })}
                  />
                  {errors?.username && (
                    <p className="p-2 text-danger ">Please enter username !</p>
                  )}
                </div>
              </div>
              <div className="col-12 ">
                <div className="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label frn-label"
                  >
                    Email address <span>**</span>
                  </label>
                  <input
                    type="email"
                    className="form-control frn-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    {...register("email", { required: true })}
                  />
                  {errors?.email && (
                    <p className="p-2 text-danger ">Please enter email !</p>
                  )}
                </div>
              </div>
              <div className="col-12 ">
                <div className="mb-3">
                  <label
                    for="exampleInputPassword1"
                    className="form-label frn-label"
                  >
                    Password <span>**</span>
                  </label>
                  <input
                    type="password"
                    className="form-control frn-control"
                    id="exampleInputPassword1"
                    placeholder="Enter password"
                    {...register("password", { required: true })}
                  />
                  {errors?.password && (
                    <p className="p-2 text-danger ">Please enter password !</p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="regi-btn w-100">
                  {" "}
                  Register now
                </button>
              </div>
              <div className="col-12 mt-3 mb-3">
                <div className="or-txt">
                  <hr />
                  <h3>or</h3>
                  <hr />
                </div>
              </div>
              <div className="col-12 w-100 ">
                <Link type="button" to={"/Sign-in"} className="regi-btn-to">
                  Login now
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;

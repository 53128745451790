import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import productone from "../images/productone.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { GetCarts, RemoveWishList, addMultiCarts } from "../redux/userSlice";
import NotFound from "../images/not_found.svg";
import toast from "react-hot-toast";
import { CiWarning } from "react-icons/ci";
import { AddCart } from "../redux/userSliceApi";
import { MdReportGmailerrorred } from "react-icons/md";
const Wishlist = () => {
  const [isLoad, setisLoad] = useState(false);
  const [AllWish, setAllWish] = useState([]);
  const Carts = useSelector(GetCarts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [RemoveList, setRemoveList] = useState([]);
  const [ProductId, setProductId] = useState("");
  const [cookie] = useCookies(["user"]);

  const handleShopNow = (id, inStock) => {
    if (inStock == "true") {
      if (Carts.some((el) => el == id)) {
        navigate("/Cart");
      } else {
        let obj = {
          user_id: cookie.user,
          product_id: id,
        };
        dispatch(AddCart(obj));
        navigate("/Cart");
      }
    } else {
      toast("Sorry Product is out off stock ! ", {
        icon: <CiWarning size={20} color="#F4D50B" />,
      });
    }
  };

  const handleAllCart = async () => {
    try {
      setisLoad(false);
      let products_id = [];
      AllWish.forEach((element) => {
        if (
          !Carts?.some((el) => el == element.id) &&
          element.inStock == "true" &&
          element.stock_quantity > 1
        ) {
          products_id.push(element.id);
        }
      });
      if (products_id?.length) {
        let obj = {
          user_id: cookie.user,
          product_id: products_id?.join(","),
        };
        await POSTAPI(ApiObj.AddMulCart, obj);
        dispatch(addMultiCarts(products_id));
        navigate("/Cart");
      } else {
        navigate("/Cart");
      }
    } catch (er) {
      console.log(er);
    } finally {
      setisLoad(true);
    }
  };
  const handleRemoveAll = async () => {
    try {
      let newArray = [];
      AllWish?.map((el) => {
        newArray.push(el.id);
      });
      dispatch(RemoveWishList(newArray));
      setAllWish((prev) => []);
      await toast.promise(
        POSTAPI(ApiObj.RemoveMultiWish, {
          user_id: cookie.user,
          product_id: newArray.join(","),
        }),
        {
          loading: "Wait Few Moment !",
          success: "All Wish Remvove Successfully !",
          error: "Something Wrong !",
        }
      );
      document.getElementById("closeWish1").click();
    } catch (error) { }
  };
  const handleUpdateList = () => {
    if (!ProductId) return;
    let obj = {
      user_id: cookie.user,
      product_id: ProductId,
    };
    toast
      .promise(POSTAPI(ApiObj.RemoveWish, obj), {
        loading: "Wait Few Moment !",
        success: "WishList Remove Successfully !",
        error: "Somethig Wrong !",
      })
      .then(() => {
        dispatch(RemoveWishList(ProductId));
        let newData = AllWish.filter((el) => el.id != ProductId);
        setAllWish(newData);
        setProductId((prev) => "");
        document.getElementById("closeWish").click();
      })
      .catch((er) => {
        console.log(er);
      });
  };
  useEffect(() => {
    (async () => {
      try {
        if (!cookie.user) return <Navigate to={"/"} replace={true} />;
        setisLoad(false);
        const data = await POSTAPI(ApiObj.ShowWish, { user_id: cookie.user });
        setAllWish(data.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, []);
  return (
    <div>
      <Header />
      <div className="aboutfirst mt-103">
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>Wishlist
        </p>
      </div>
      <div className="container p-main-80">
        <div className="responsive-table">
          <table class="table">
            <thead>
              <tr className="table-head">
                <th scope="col">Product Name</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Stock Status</th>
                <th scope="col">Add to cart</th>
              </tr>
            </thead>
            <tbody>
              {!isLoad ? (
                <tr>
                  <td colSpan={4}>
                    <div className="d-flex  flex-column my-4 justify-content-center  align-items-center my-2">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              ) : AllWish?.length ? (
                AllWish?.map((item) => {
                  return (
                    <tr className="table-data">
                      <td>
                        <div className="table-pro-name">
                          <img
                            src={item.imageUrl}
                            onError={(e) => {
                              e.currentTarget.src = productone;
                            }}
                            alt=""
                          />
                          <p>{item?.name}</p>
                        </div>
                      </td>
                      <td>
                        <div className="tbl-pro-price">
                          <p>$ {item?.price}</p>
                        </div>
                      </td>
                      <td>
                        {item?.inStock == "true" && item?.stock_quantity > 1 ? (
                          <div className="tbl-in-stock">
                            <p>
                              <span>
                                {" "}
                                <i class="fa-solid fa-circle-check"></i>
                              </span>
                              In Stock
                            </p>
                          </div>
                        ) : (
                          <div className="tbl-in-stock tbl-out-stock">
                            <p>
                              <span>
                                {" "}
                                <i class="fa-solid fa-circle-xmark"></i>
                              </span>
                              Out Off Stock
                            </p>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="tbl-shop-now">
                          <div className="tbl-sec-txt">
                            <button
                              onClick={() => {
                                if (item.stock_quantity < 1) {
                                  return toast(
                                    "Sorry Product is out off stock !  ",
                                    {
                                      icon: (
                                        <CiWarning size={20} color="#F4D50B" />
                                      ),
                                    }
                                  );
                                }
                                handleShopNow(item.id, item.inStock);
                              }}
                            >
                              SHOP NOW<i class="fa-solid fa-angles-right"></i>
                            </button>
                          </div>
                          <div className="cancle-tbl">
                            <Link
                              onClick={() => setProductId(item.id)}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <i class="fa-solid fa-xmark"></i>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className="d-flex  flex-column  justify-content-center  align-items-center my-2">
                      <img src={NotFound} className="w-25 h-25" />
                      <p>WishList Not Found !</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="three-btn">
          {AllWish?.length || RemoveList?.length ? (
            <div className="two-btn-tbl">
              <div className="tbl-first-sec-txt">
                <button
                  className="con-shoping"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop2"
                >
                  Clear wishlist<i class="fa-solid fa-angles-right"></i>
                </button>
              </div>
            </div>
          ) : null}

          <div className="tbl-sec-txt">
            {AllWish?.length ? (
              <p className="con-shoping" onClick={handleAllCart}>
                Continue shopping<i class="fa-solid fa-angles-right"></i>
              </p>
            ) : null}
          </div>
        </div>
        {/* Modal */}
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class=" fs-5" id="staticBackdropLabel">
                  Remove From WishList
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body w-100 ">
                <div className="d-flex justify-content-center  align-items-center  flex-column ">
                  <MdReportGmailerrorred size={100} color="F73131" />
                  <p
                    style={{ fontSize: "20px" }}
                    className="text-capitalize "
                  >
                    are you sure you want to remove ?
                  </p>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  id="closeWish"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={handleUpdateList}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* End Modal */}
        {/* Modal */}
        <div
          class="modal fade"
          id="staticBackdrop2"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class=" fs-5" id="staticBackdropLabel">
                  Remove All WishList
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body w-100 ">
                <div className="d-flex justify-content-center  align-items-center  flex-column ">
                  <MdReportGmailerrorred size={100} color="F73131" />
                  <p
                    style={{ fontSize: "20px" }}
                    className="text-capitalize "
                  >
                    are you sure you want to remove all ?
                  </p>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  id="closeWish1"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={handleRemoveAll}
                >
                  Remove All
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* End Modal */}
      </div>
      <Footer />
    </div>
  );
};

export default Wishlist;

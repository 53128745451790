import React, { useEffect, useState } from "react";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";

const useCoutry = () => {
  const [Coutry, setCoutry] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const result = await POSTAPI(ApiObj.ShowCountry);
        setCoutry((prev) => result.data);
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);

  return Coutry;
};

export default useCoutry;

import React, { useState } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Slider } from "primereact/slider";

export default function RangeDemo({ rage, setrage }) {
  return (
    <div className="card flex justify-content-center">
      <Slider
        value={rage}
        onChange={(e) => {
          setrage(e.value);
        }}
        className="w-14rem"
        range
      />
    </div>
  );
}

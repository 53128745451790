import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import homeslidertwofirst from "../images/homeslidertwo-one.svg";
import homeslidertwosecond from "../images/homeslidertwosecond.svg";

function HomeSlidertwo() {
  const settings = {
    // dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 200,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings} className="d-flex ">
        <div className="container">
          <div className="hometwo-slider-design">
            <div className="row">
              <div className="col-lg-4 col-md-5 col-12">
                <div className="slider-two-img">
                  <img
                    src={homeslidertwofirst}
                    className="img-fluid"
                    alt="ee"
                  ></img>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 col-12">
                <div className="slider-two-txt">
                  <h3>
                    “Diversity is a cornerstone of our farming philosophy. We
                    carefully select a wide range of crops, ensuring a varied
                    and balanced ecosystem. This not only helps to naturally
                    deter pests and diseases but also promotes soil health by
                    varying the nutrient demands of different plants.”
                  </h3>
                  <h4>Alexan Micelito</h4>
                  <h5>Customer of Our Shop</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="hometwo-slider-design">
            <div className="row">
              <div className="col-lg-4 col-md-5 col-12">
                <div className="slider-two-img">
                  <img
                    src={homeslidertwosecond}
                    className="img-fluid"
                    alt="ee"
                  ></img>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 col-12">
                <div className="slider-two-txt">
                  <h3>
                    “Diversity is a cornerstone of our farming philosophy. We
                    carefully select a wide range of crops, ensuring a varied
                    and balanced ecosystem. This not only helps to naturally
                    deter pests and diseases but also promotes soil health by
                    varying the nutrient demands of different plants.”
                  </h3>
                  <h4>Alexan Micelito</h4>
                  <h5>Customer of Our Shop</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="hometwo-slider-design">
            <div className="row">
              <div className="col-lg-4 col-md-5 col-12">
                <div className="slider-two-img">
                  <img
                    src={homeslidertwofirst}
                    className="img-fluid"
                    alt="ee"
                  ></img>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 col-12">
                <div className="slider-two-txt">
                  <h3>
                    “Diversity is a cornerstone of our farming philosophy. We
                    carefully select a wide range of crops, ensuring a varied
                    and balanced ecosystem. This not only helps to naturally
                    deter pests and diseases but also promotes soil health by
                    varying the nutrient demands of different plants.”
                  </h3>
                  <h4>Alexan Micelito</h4>
                  <h5>Customer of Our Shop</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default HomeSlidertwo;

import React, { useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import productone from "../../images/productone.svg";
import useGetFilter from "../../hooks/useGetFilter";
import useGetFilterProduct from "../../hooks/useGetFilterProduct";
import NotFound from "../../images/not_found.svg";
import { GetCarts, ShowWishList } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { CiWarning } from "react-icons/ci";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import {
  AddCart,
  AddWishApi,
  RemoveCartApi,
  RemoveWishApi,
} from "../../redux/userSliceApi";

const PopulerProduct = () => {
  const { Filters } = useGetFilter();
  const [SelectFilter, setSelectFilter] = useState(null);
  const { isProductLoad, Products } = useGetFilterProduct(SelectFilter);
  const [cookie] = useCookies(["user"]);
  const dispatch = useDispatch();
  const Carts = useSelector(GetCarts);
  const Wishs = useSelector(ShowWishList);
  const handleCart = (id, stock) => {
    if (stock != "true") {
      return toast("Sorry Product is out off stock ! ", {
        icon: <CiWarning size={20} color="#F4D50B" />,
      });
    }
    let obj = {
      user_id: cookie.user,
      product_id: id,
    };
    toast.dismiss();
    if (Carts.some((el) => el == id)) {
      toast.success("Cart Remove Successfully !");
      dispatch(RemoveCartApi(obj));
    } else {
      toast.success("Cart Add Successfully !");
      dispatch(AddCart(obj));
    }
  };
  const handleWish = (id) => {
    if (!cookie?.user)
      return toast("Please Login First ", {
        icon: <CiWarning size={20} color="#F4D50B" />,
      });
    let obj = {
      user_id: cookie.user,
      product_id: id,
    };
    toast.dismiss();
    if (Wishs.some((el) => el == id)) {
      toast.success("Wish Remove Successfully !");
      dispatch(RemoveWishApi(obj));
    } else {
      toast.success("Wish Add Successfully !");
      dispatch(AddWishApi(obj));
    }
  };
  return (
    <>
      <div className="container p-main-80">
        <div className="home-heading">
          <h2>Popular Products</h2>
          <div className="tab-design-home">
            <ul
              className="nav nav-pills home-pills"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => setSelectFilter(null)}
                  className="nav-link active"
                  id="all-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#all"
                  type="button"
                  role="tab"
                  aria-controls="all"
                  aria-selected="true"
                >
                  All
                </button>
              </li>
              {Filters.Categories?.map((item) => {
                return (
                  <li className="nav-item" role="presentation">
                    <button
                      onClick={() => setSelectFilter(item?.id)}
                      className="nav-link text-capitalize"
                      id="all-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#all"
                      type="button"
                      role="tab"
                      aria-controls="all"
                      aria-selected="true"
                    >
                      {item.name?.replace("_", " ")}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane   show active"
              id="all"
              role="tabpanel"
              aria-labelledby="all-tab"
              data-mdb-animation="fade-in"
            >
              <div className="row spl-box-item">
                {isProductLoad ? (
                  Products?.filter((el) => {
                    if (SelectFilter) return el.type == SelectFilter;
                    return el;
                  }).length ? (
                    Products.filter((el) => {
                      if (SelectFilter) return el.type == SelectFilter;
                      return el;
                    })
                      ?.splice(0, 8)
                      .map((item) => {
                        return (
                          <div className="col-lg-3 col-md-4 col-6 mb-3 spl-box-item-two">
                            <Link to={`/AboutItem/${item?.id}`}>
                              <div className="product-box-bg ">
                                <div className="product-img">
                                  <img
                                    src={item?.image}
                                    className="img-fluid"
                                    alt="first"
                                    onError={(event) => {
                                      event.currentTarget.src = productone;
                                      event.currentTarget.className = "error";
                                    }}
                                  />
                                  <div className="div-hide">
                                    <div className="cart-wishlist">
                                      <Link
                                        onClick={() => {
                                          if (!cookie.user)
                                            return toast(
                                              "Please Login First ",
                                              {
                                                icon: (
                                                  <CiWarning
                                                    size={20}
                                                    color="#F4D50B"
                                                  />
                                                ),
                                              }
                                            );
                                          if (item.stock_quantity < 1) {
                                            return toast(
                                              "Sorry Product is out off stock !  ",
                                              {
                                                icon: (
                                                  <CiWarning
                                                    size={20}
                                                    color="#F4D50B"
                                                  />
                                                ),
                                              }
                                            );
                                          }
                                          handleCart(item.id, item.inStock);
                                        }}
                                        to={`${
                                          !cookie.user
                                            ? "/Sign-in"
                                            : item?.inStock != "true"
                                            ? ""
                                            : "/Cart"
                                        }`}
                                        className={`${
                                          Carts.some((el) => el == item.id) &&
                                          "cart-active"
                                        }`}
                                      >
                                        <PiShoppingCartSimpleBold />
                                      </Link>
                                      <Link
                                        onClick={() => {
                                          if (!cookie.user)
                                            return toast(
                                              "Please Login First ",
                                              {
                                                icon: (
                                                  <CiWarning
                                                    size={20}
                                                    color="#F4D50B"
                                                  />
                                                ),
                                              }
                                            );
                                          handleWish(item.id);
                                        }}
                                        to={`${
                                          !cookie.user
                                            ? "/Sign-in"
                                            : "/WishList"
                                        }`}
                                        className={`${
                                          Wishs.some((el) => el == item.id) &&
                                          "cart-active"
                                        }`}
                                      >
                                        <FaRegHeart />
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="product-txt-position ">
                                    <h4
                                      style={{ backgroundColor: item?.color }}
                                    >
                                      {item?.tag}
                                    </h4>
                                  </div>
                                </div>
                                <h3>{item?.name}</h3>
                                <h5>
                                  {item?.price} $
                                  <span>{item?.base_price} $</span>
                                </h5>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                  ) : (
                    <div className="d-flex justify-content-center  alig-align-items-center ">
                      <img src={NotFound} className="w-25 h-100" />
                    </div>
                  )
                ) : (
                  <div>
                    <div className="loader"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopulerProduct;

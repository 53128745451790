import React from "react";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import logo from "../images/logo-header.svg";
import { TbGardenCart } from "react-icons/tb";
import { LuUser } from "react-icons/lu";
import { FaRegHeart } from "react-icons/fa";
import useGetUser from "../hooks/useGetUser";
import { useSelector } from "react-redux";
import { GetCarts } from "../redux/userSlice";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { CiWarning } from "react-icons/ci";

const Header = () => {
  let location = useLocation();
  useGetUser();
  const [cookie, setCookies, removeCookie] = useCookies(["user"]);
  const Carts = useSelector(GetCarts);
  return (
    <div>
      <div className="main-header fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light py-0">
          <div className="container">
            <NavLink className="navbar-brand" to={"/"}>
              <img src={logo}></img>
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => {
                document
                  .getElementById("navbarSupportedContent")
                  .classList.toggle("show");
              }}

              // data-bs-toggle="collapse"
              // data-bs-target="#navbarSupportedContent"
              // aria-controls="navbarSupportedContent"
              // aria-expanded="false"
              // aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li
                  className="nav-item"
                  onClick={() => {
                    document
                      .getElementById("navbarSupportedContent")
                      .classList.toggle("show");
                  }}
                >
                  <NavLink
                    className={`nav-link   ${
                      location.pathname == "/" && "link-active "
                    }`}
                    aria-current="page"
                    to={"/"}
                  >
                    Home
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    document
                      .getElementById("navbarSupportedContent")
                      .classList.toggle("show");
                  }}
                >
                  <NavLink
                    className={`nav-link   ${
                      location.pathname == "/About" && "link-active "
                    }`}
                    aria-current="page"
                    to={"/About"}
                  >
                    About us
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    document
                      .getElementById("navbarSupportedContent")
                      .classList.toggle("show");
                  }}
                >
                  <NavLink
                    className={`nav-link   ${
                      location.pathname == "/Shop" && "link-active "
                    }`}
                    aria-current="page"
                    to={"/Shop"}
                  >
                    Shop
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to={"/MyOrder"}
                  >
                    My Orders
                  </NavLink>
                </li> */}
                {cookie?.user ? (
                  <li
                    className="nav-item"
                    onClick={() => {
                      document
                        .getElementById("navbarSupportedContent")
                        .classList.toggle("show");
                    }}
                  >
                    <NavLink
                      className={`nav-link   ${
                        location.pathname == "/MyOrder" && "link-active "
                      }`}
                      aria-current="page"
                      to={"/MyOrder"}
                    >
                      My Orders
                    </NavLink>
                  </li>
                ) : (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to={"/Sign-in"}
                      onClick={() => {
                        toast("Please Login First ", {
                          icon: <CiWarning size={20} color="#F4D50B" />,
                        });
                      }}
                    >
                      My Orders
                    </NavLink>
                  </li>
                )}

                <li
                  className="nav-item"
                  onClick={() => {
                    document
                      .getElementById("navbarSupportedContent")
                      .classList.toggle("show");
                  }}
                >
                  <NavLink
                    className={`nav-link   ${
                      location.pathname == "/ContactUs" && "link-active "
                    }`}
                    aria-current="page"
                    to={"/ContactUs"}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <form className="d-sm-flex align-items-baseline">
                {/* <div className="form-group has-search header-bottom-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div> */}
                {cookie?.user ? (
                  <div className="cart-user-heart d-flex">
                    <Link to={"#"}>
                      <div className="nav-item dropdown">
                        <NavLink
                          className="nav-link ms-0"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <LuUser />
                        </NavLink>
                        <ul
                          className="dropdown-menu log-out-drop"
                          aria-labelledby="navbarDropdown"
                        >
                          <li
                            className="ms-0"
                            onClick={() => {
                              document
                                .getElementById("navbarSupportedContent")
                                .classList.toggle("show");
                            }}
                          >
                            <Link
                              className="dropdown-item ms-0"
                              to={"/Profile"}
                            >
                              Profile
                            </Link>
                          </li>
                          <li className="ms-0">
                            <Link
                              className="dropdown-item ms-0"
                              onClick={() => {
                                removeCookie("user");
                                toast.success("Logout Successfully !");
                              }}
                              to={"/"}
                            >
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Link>
                    <Link
                      to={"/Wishlist"}
                      className={` ${
                        location.pathname == "/Wishlist" && "link-active "
                      }`}
                    >
                      <FaRegHeart />
                    </Link>
                    <Link
                      to={"/Cart"}
                      className={`cart-header ${
                        location.pathname == "/Cart" && "link-active "
                      }`}
                    >
                      <TbGardenCart />
                      <span
                        className={`cart-title ${
                          location.pathname == "/Cart" && "link-active "
                        }`}
                      >
                        {Carts?.length}
                      </span>
                    </Link>
                    <span
                      className={`cart-title ${
                        location.pathname == "/Cart" && "link-active "
                      }`}
                    >
                      Cart
                    </span>
                  </div>
                ) : (
                  <div className="login-btn">
                    <Link to={"/Sign-in"}>Login</Link>
                  </div>
                )}
              </form>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;

import homefirstslider from "../images/homefirstslider.svg";
import productone from "../images/productone.svg";
import NotFound from "../images/not_found.svg";
import useGetFilterProduct from "../hooks/useGetFilterProduct";
import { Link, Navigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, EffectFade, Navigation } from "swiper/modules";

function HomeSlider({ Filter }) {
  const { Products } = useGetFilterProduct();

  return (
    <div className="slider-container">
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        navigation={true} // Add navigation buttons
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Navigation, Autoplay, Pagination, EffectFade]}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          320: {
            center: true,
            slidesPerView: 1.2,
          },
          375: {
            center: true,
            slidesPerView: 1.5,
          },
          480: {
            slidesPerView: 2,
          },
          576: {
            slidesPerView: 2.2,
          },
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
          },
          1280: {
            slidesPerView: 4,
          },
          1440: {
            slidesPerView: 4,
          },
        }}
        className="mySwiper"
      >
        {Products?.filter((el) => {
          if (Filter) return el.type == Filter;
          return el;
        }).length ? (
          Products.filter((el) => {
            if (Filter) return el.type == Filter;
            return el;
          })
            ?.splice(0, 8)
            ?.map((item) => {
              return (
                <>
                  <SwiperSlide>
                    <div className=" ">
                      <Link to={`/AboutItem/${item?.id}`} className="container">
                        <div className="home-slider-design  ">
                          <div className="home-first-imgslider">
                            <img
                              src={item.image}
                              onError={(e) =>
                                (e.currentTarget.src = productone)
                              }
                              className="img-fluid"
                              alt="ss"
                            ></img>
                            <div className="sticky-top-txt">
                              <h4 style={{ backgroundColor: item?.color }}>
                                {item.tag}
                              </h4>
                            </div>
                          </div>
                          <h2>{item?.type_name}</h2>
                          <h3>{item?.name}</h3>
                          <h5>{item?.price}</h5>
                          <div className="rating-product">
                            <h6>({item?.totalReview} Reviews)</h6>
                            <div className="d-flex-rate">
                              {[...Array(5).keys()].map((val, index) => {
                                return (
                                  <i
                                    className={`fa-solid fa-star ${
                                      Math.floor(item?.avgRate) >= index + 1 &&
                                      "star-active"
                                    }`}
                                  ></i>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                </>
              );
            })
        ) : (
          <div className="d-flex  justify-content-center  align-items-center  flex-column ">
            <h3 className="text-white my-4 py-4">Product Not Found !</h3>
          </div>
        )}
      </Swiper>
    </div>
  );
}

export default HomeSlider;

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Scrolltotop from "./Scrolltotop";
import Home from "./Components/Home";
import CreateAccount from "./Components/CreateAccount";
import LoginPage from "./Components/LoginPage";
import About from "./Components/About";
import Shop from "./Components/Shop";
import PaymentOption from "./Components/PaymentOption";
import ContactUs from "./Components/ContactUs";
import Wishlist from "./Components/Wishlist";
import Cart from "./Components/Cart";
import MyOrder from "./Components/MyOrder";
import AboutItem from "./Components/AboutItem";
import OrderDetail from "./Components/OrderDetail";
import { Toaster } from "react-hot-toast";
import VerifyEmail from "./Components/Verify-email";
import ForgatePassword from "./Components/ForgatePassword";
import ReduxProvider from "./provider/ReduxProvider";
import SuccessOrder from "./Components/SuccessOrder";
import CancelOrder from "./Components/CancelOrder";
import Profile from "./Components/Profile";
function App() {
  return (
    <div className="App">
      <ReduxProvider>
        <Router>
          <Scrolltotop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/CreateAccount" element={<CreateAccount />} />
            <Route path="/Sign-in" element={<LoginPage />} />
            <Route path="/About" element={<About />} />
            <Route path="/Shop" element={<Shop />} />
            <Route path="/PaymentOption" element={<PaymentOption />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Wishlist" element={<Wishlist />} />
            <Route path="/Cart" element={<Cart />} />
            <Route path="/MyOrder" element={<MyOrder />} />
            <Route path="/AboutItem/:id" element={<AboutItem />} />
            <Route path="/OrderDetail/:id" element={<OrderDetail />} />
            <Route path="/Send-otp" element={<VerifyEmail />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/ForgatPassword/:id" element={<ForgatePassword />} />
            <Route path="/Order-confirm/:id" element={<SuccessOrder />} />
            <Route path="/Order-cancel/:id" element={<CancelOrder />} />
          </Routes>
        </Router>
        <Toaster position="top-right" reverseOrder={false} />
      </ReduxProvider>
    </div>
  );
}

export default App;

export const Main = "https://urvibazaar-api.4born.in";
// export const Main = "http://localhost:8000";

export const ApiObj = {
  Login: `${Main}/api/user/login`,
  Register: `${Main}/api/user/register`,
  GetUserDetal: `${Main}/api/user/get-user`,
  ShowCountry: `${Main}/api/user/get-country`,
  UploadImage: `${Main}/api/user/upload-image`,
  AddReview: `${Main}/api/user/add-review`,
  UpdateUser: `${Main}/api/user/update-user`,
  // ForgatePassword and Contact Api
  SendOtp: `${Main}/api/user/send-otp`,
  ChangePassword: `${Main}/api/user/change-password`,
  GetTouch: `${Main}/api/user/get-touch`,
  // Product
  ShowFilterProduct: `${Main}/api/user/show-popular-product`,
  ShowFilters: `${Main}/api/product/show-filters`,
  ShowProduct: `${Main}/api/product/show-product`,
  ShowProductDetail: `${Main}/api/product/show-productdetail`,
  // Payment
  StripePayment: `${Main}/api/product/checkout-session`,
  ChangeStatus: `${Main}/api/product/change-status`,
  PendingPayment: `${Main}/api/product/pending-payment`,
  // Coupon
  ShowCoupon: `${Main}/api/product/show-coupon`,
  // Cart
  AddCart: `${Main}/api/user/add-cart`,
  ShowCart: `${Main}/api/user/show-cart`,
  RemoveCart: `${Main}/api/user/remove-cart`,
  AddMulCart: `${Main}/api/user/add-mulcart`,
  ChangeQuantity: `${Main}/api/user/change-cart`,
  //Wish
  WishAdd: `${Main}/api/user/add-wishlist`,
  RemoveWish: `${Main}/api/user/remove-wishlist`,
  ShowWish: `${Main}/api/user/show-wishlist`,
  RemoveMultiWish: `${Main}/api/user/remove-mulwishlist`,
  // Order
  ShowOrder: `${Main}/api/user/show-order`,
  ShowOrderDetail: `${Main}/api/user/show-order-detail`,
  ShowOrderProduct: `${Main}/api/user/show-order-product`,
};

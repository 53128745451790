import React, { useEffect, useState } from "react";
import { ApiObj } from "../utils/Api";
import { POSTAPI } from "../helper/integration.";

const useGetOrderDetail = (id) => {
  const [isLoad, setisLoad] = useState(false);
  const [OrderDetail, setOrders] = useState([]);
  const [Products, setProducts] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setisLoad(true);
        const data = await POSTAPI(ApiObj.ShowOrderDetail, { order_id: id });
        const Product = await POSTAPI(ApiObj.ShowOrderProduct, {
          id: data.data.id,
        });
        setOrders((prev) => data.data);
        setProducts((prev) => Product.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [id]);
  return { isLoad, OrderDetail, Products };
};

export default useGetOrderDetail;

import { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PriceFilter from "./PriceFilter";
import productone from "../images/productone.svg";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { CiWarning } from "react-icons/ci";
import { FaRegHeart } from "react-icons/fa6";
// import Slider from './Slider';
import { Link, useNavigate } from "react-router-dom";
import useGetFilter from "../hooks/useGetFilter";
import { POSTAPI } from "../helper/integration.";
import { ApiObj } from "../utils/Api";
import Paggination from "../common/Paggination";
import { useRef } from "react";
import useGetFilterProduct from "../hooks/useGetFilterProduct";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import {
  AddCart,
  AddWishApi,
  RemoveCartApi,
  RemoveWishApi,
} from "../redux/userSliceApi";
import toast from "react-hot-toast";
import { GetCarts, ShowWishList } from "../redux/userSlice";
import NotFound from "../images/not_found.svg";
function per(num, amount) {
  return (num * amount) / 100;
}

function Shop() {
  const { Filters } = useGetFilter();
  const { Products } = useGetFilterProduct();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Carts = useSelector(GetCarts);
  const Wishs = useSelector(ShowWishList);
  const [cookie] = useCookies(["user"]);
  const [AllData, setAllData] = useState([]);
  const [MainData, setMainData] = useState([]);
  const [MaxValue, setMaxValue] = useState(null);
  const [CheckFilters, setCheckFilters] = useState([]);
  const [BaseFilter, setBaseFilter] = useState("");
  const [SearchFilter, setSearchFilter] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [recordsPerPage] = useState(12);
  const [nPage, setnPage] = useState([1]);
  const [isUpdate, setisUpdate] = useState(false);
  const [rage, setrage] = useState(null);
  const [isLoad, setisLoad] = useState(false);
  const searchRef = useRef(null);
  const handleSearch = (e) => {
    let val = e.target.value;
    let newArray = AllData?.filter((item) =>
      item.name.toLowerCase().includes(val.toLowerCase())
    );

    setcurrentPage(1);
    setSearchFilter(newArray);
    const indexOfLastRecord = 1 * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = newArray.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    setMainData(currentRecords);
    const nPages = Math.ceil(newArray?.length / recordsPerPage);
    const num = [...Array(nPages + 1).keys()].slice(1);
    setnPage(num);
  };

  const handleChangePage = (id) => {
    setcurrentPage(id);
    const indexOfLastRecord = id * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = SearchFilter?.length
      ? SearchFilter.slice(indexOfFirstRecord, indexOfLastRecord)
      : AllData.slice(indexOfFirstRecord, indexOfLastRecord);
    setMainData(currentRecords);
  };

  const handleApplyFilter = () => {
    document.getElementById("collapseTwo").classList.toggle("show");
    setisUpdate(!isUpdate);
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setBaseFilter((prev) => value);
    document.getElementById("collapseTwo").classList.toggle("show");
    setisUpdate(!isUpdate);
  };
  const handleCart = (id, stock) => {
    if (stock != "true") {
      return toast("Sorry Product is out off stock ! ", {
        icon: <CiWarning size={20} color="#F4D50B" />,
      });
    }
    let obj = {
      user_id: cookie.user,
      product_id: id,
    };
    toast.dismiss();
    if (Carts.some((el) => el == id)) {
      toast.success("Cart Remove Successfully !");
      dispatch(RemoveCartApi(obj));
    } else {
      toast.success("Cart Add Successfully !");
      dispatch(AddCart(obj));
    }
  };
  const handleWish = (id) => {
    if (!cookie?.user)
      return toast("Please Login First ", {
        icon: <CiWarning size={20} color="#F4D50B" />,
      });
    let obj = {
      user_id: cookie.user,
      product_id: id,
    };
    toast.dismiss();
    if (Wishs.some((el) => el == id)) {
      toast.success("Wish Remove Successfully !");
      dispatch(RemoveWishApi(obj));
    } else {
      toast.success("Wish Add Successfully !");
      dispatch(AddWishApi(obj));
    }
  };
  useEffect(() => {
    (async () => {
      try {
        setisUpdate(false);
        let obj = {};
        if (CheckFilters?.length) {
          obj["type"] = CheckFilters?.join(",");
        }
        if (rage?.length) {
          let min = per(MaxValue, rage[0]);
          let max = per(MaxValue, rage[1]);
          obj["price"] = `${min},${max}`;
        }
        if (BaseFilter?.length) {
          obj["orderprice"] = BaseFilter;
        }
        const data = await POSTAPI(ApiObj.ShowProduct, obj);
        setAllData((prev) => data.data);
        setSearchFilter([]);
        searchRef.current.value = "";
        let newArray = [];
        data.data.map((el) => {
          newArray.push(Number(el.price));
        });
        if (!MaxValue) {
          setMaxValue((prev) => Math.max(...newArray));
        }
        let indexOfLastRecord = currentPage * recordsPerPage;
        let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        let currentRecords = data.data.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        setMainData((prev) => currentRecords);
        let nPages = Math.ceil(data?.data?.length / recordsPerPage);
        let num = [...Array(nPages + 1).keys()].slice(1);
        setnPage(num);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      {<Header />}
      <div className="aboutfirst mt-103">
        <p>
          {" "}
          <Link to={"/Home"}>Home</Link>
          <span className="px-3 text-dark">/</span>Shop
        </p>
      </div>
      <div className="container p-main-80">
        <div className="search-sort">
          <div className="input-group search-shop w-auto me-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
              ref={searchRef}
              onChange={handleSearch}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
            >
              <span className="fa fa-search form-control-feedback"></span>
            </button>
          </div>
          <div className="filter-mobile-view">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    onClick={() => {
                      document
                        .getElementById("collapseTwo")
                        .classList.toggle("show");
                    }}
                    className="accordion-button collapsed px-0"
                    type="button"
                    //  data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                    aria-controls="collapseTwo"
                  ></button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse shadow-filter"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body px-3 mt-2">
                    <div className="d-flex align-items-center mb-3">
                      <div className="mt-0 sort-by me-3">
                        <label for="inputPassword6" className="col-form-label">
                          Sort by :
                        </label>
                      </div>
                      <div className="mt-0 sort-by">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={handleFilterChange}
                        >
                          <option selected value="">
                            Open this select
                          </option>
                          <option value="1">High - Low Price</option>
                          <option value="2">Low - High Price</option>
                          <option value="3">Top Rated </option>
                          <option value="4">Low Rated </option>
                        </select>
                      </div>
                    </div>
                    <div className="product-category-all">
                      <h5>PRODUCT CATEGORIES</h5>
                      <div className="pro-check-box">
                        <div className="total-all-pro">
                          <div>
                            <input
                              type="checkbox"
                              className="d-none"
                              id="cbox1"
                              value="third_checkbox"
                              checked={
                                !CheckFilters?.length
                                  ? true
                                  : CheckFilters?.length ==
                                    Filters?.Categories?.length
                              }
                              onChange={() => {
                                setCheckFilters((prev) =>
                                  Filters?.Categories?.map((item) => item?.id)
                                );
                              }}
                            />{" "}
                            <label for="cbox1"> All </label>
                          </div>
                          <p>{Filters?.All?.total_prodduct}</p>
                        </div>
                        {Filters?.Categories?.map((item, index) => {
                          return (
                            <div className="total-all-pro">
                              <div>
                                <input
                                  type="checkbox"
                                  className="d-none "
                                  id={`demo${item.name}`}
                                  value="third_checkbox"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setCheckFilters((prev) => [
                                        ...prev,
                                        item.id,
                                      ]);
                                    } else {
                                      setCheckFilters((prev) =>
                                        prev.filter((el) => el != item.id)
                                      );
                                    }
                                  }}
                                />{" "}
                                <label
                                  for={`demo${item.name}`}
                                  className={`text-capitalize  ${
                                    CheckFilters?.some((el) => el == item.id) &&
                                    "filter-check"
                                  }`}
                                >
                                  {item?.name?.replace("_", " ")}
                                </label>
                              </div>
                              <p>{item?.Total}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="product-category-all">
                      <h5>Filter by price</h5>
                      <div className="pro-check-box">
                        <PriceFilter rage={rage} setrage={setrage} />
                        <div className="filter-price mb-0">
                          <button
                            className="btn btn-filter"
                            onClick={handleApplyFilter}
                          >
                            Filter
                          </button>
                          <p>
                            Price :{" "}
                            <span>
                              ${rage?.length ? per(MaxValue, rage[0]) : 0}
                            </span>{" "}
                            -{" "}
                            <span>
                              $
                              {rage?.length ? per(MaxValue, rage[1]) : MaxValue}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" d-md-block d-none">
            <div className="row g-3 align-items-center short-by-all-list">
              <div className="col-auto mt-0 sort-by">
                <label for="inputPassword6" className="col-form-label">
                  Sort by :
                </label>
              </div>
              <div className="col-auto mt-0 sort-by">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleFilterChange}
                >
                  <option selected value="">
                    Open this select
                  </option>
                  <option value="1">High - Low Price</option>
                  <option value="2">Low - High Price</option>
                  <option value="3">Top Rated </option>
                  <option value="4">Low Rated </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-12 my-2 order-2 order-md-1">
            <div className="product-category-all  d-md-block d-none ">
              <h5>PRODUCT CATEGORIES</h5>
              <div className="pro-check-box">
                <div className="total-all-pro">
                  <div>
                    <input
                      type="checkbox"
                      className="d-none"
                      id="cbox1"
                      value="third_checkbox"
                      checked={
                        !CheckFilters?.length
                          ? true
                          : CheckFilters?.length == Filters?.Categories?.length
                      }
                      onChange={() => {
                        setCheckFilters((prev) =>
                          Filters?.Categories?.map((item) => item?.id)
                        );
                      }}
                    />{" "}
                    <label for="cbox1"> All </label>
                  </div>
                  <p>{Filters?.All?.total_prodduct}</p>
                </div>
                {Filters?.Categories?.map((item, index) => {
                  return (
                    <div className="total-all-pro">
                      <div>
                        <input
                          type="checkbox"
                          className="d-none "
                          id={`demo${item.name}`}
                          value="third_checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCheckFilters((prev) => [...prev, item.id]);
                            } else {
                              setCheckFilters((prev) =>
                                prev.filter((el) => el != item.id)
                              );
                            }
                          }}
                        />{" "}
                        <label
                          for={`demo${item.name}`}
                          className={`text-capitalize  ${
                            CheckFilters?.some((el) => el == item.id) &&
                            "filter-check"
                          }`}
                        >
                          {item?.name?.replace("_", " ")}
                        </label>
                      </div>
                      <p>{item?.Total}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="product-category-all  d-md-block d-none">
              <h5>Filter by price</h5>
              <div className="pro-check-box">
                <PriceFilter rage={rage} setrage={setrage} />
                <div className="filter-price">
                  <button
                    className="btn btn-filter"
                    onClick={handleApplyFilter}
                  >
                    Filter
                  </button>
                  <p>
                    Price :{" "}
                    <span>${rage?.length ? per(MaxValue, rage[0]) : 0}</span> -{" "}
                    <span>
                      ${rage?.length ? per(MaxValue, rage[1]) : MaxValue}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="product-category-all">
              <h5>top rate</h5>
              <div className="product-category-all-two">
                {Products?.slice(0, 5).map((item) => {
                  return (
                    <Link
                      to={`/AboutItem/${item?.id}`}
                      className="pro-check-box"
                    >
                      <div className="top-rate-pro">
                        <img
                          src={item?.image}
                          onError={(e) => (e.currentTarget.src = productone)}
                          alt=""
                          className="img-fluid"
                        />
                        <div className="top-pro-price">
                          <p>{item?.name}</p>
                          <h4>{item?.price} $</h4>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            {/* <div className="product-category-all">
              <h5>product tags</h5>
              <div className="pro-check-box">
                <div className="pro-teg">
                  <p>Plant</p>
                  <p>Floor</p>
                  <p>Indoor</p>
                  <p>Green</p>
                  <p>Healthy</p>
                  <p>Cactus</p>
                  <p>House plant</p>
                  <p>Office tree</p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-lg-9 col-md-8 col-12 my-2 order-1 order-md-2">
            <div className="row">
              {!isLoad ? (
                <div className="d-flex justify-content-center align-items-center   flex-column w-100  ">
                  <div className="loader"></div>
                </div>
              ) : !MainData?.length ? (
                <div className="d-flex justify-content-center align-items-center   flex-column w-100  ">
                  <img src={NotFound} className="w-75 h-75" />
                  <p>Product Not Found !</p>
                </div>
              ) : (
                MainData?.map((item) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-6 mb-3">
                      <Link to={`/AboutItem/${item.id}`}>
                        <div className="product-box-bg ">
                          <div className="product-img">
                            <img
                              onError={(event) => {
                                event.currentTarget.src = productone;
                              }}
                              src={item?.imageUrl}
                              className="img-fluid"
                              alt="first"
                              loading="lazy"
                            />
                            <div className="div-hide">
                              <div className="cart-wishlist">
                                <Link
                                  onClick={() => {
                                    if (!cookie.user)
                                      return toast("Please Login First ", {
                                        icon: (
                                          <CiWarning
                                            size={20}
                                            color="#F4D50B"
                                          />
                                        ),
                                      });
                                    if (item.stock_quantity < 1) {
                                      return toast(
                                        "Sorry Product is out off stock !  ",
                                        {
                                          icon: (
                                            <CiWarning
                                              size={20}
                                              color="#F4D50B"
                                            />
                                          ),
                                        }
                                      );
                                    }
                                    handleCart(item.id, item.inStock);
                                  }}
                                  to={`${!cookie.user ? "/Sign-in" : ""}`}
                                  className={`${
                                    Carts.some((el) => el == item.id) &&
                                    "cart-active"
                                  }`}
                                >
                                  <PiShoppingCartSimpleBold />
                                </Link>
                                <Link
                                  to={`${!cookie.user ? "/Sign-in" : ""}`}
                                  className={`${
                                    Wishs.some((el) => el == item.id) &&
                                    "cart-active"
                                  }`}
                                  onClick={() => {
                                    if (!cookie.user)
                                      return toast("Please Login First ", {
                                        icon: (
                                          <CiWarning
                                            size={20}
                                            color="#F4D50B"
                                          />
                                        ),
                                      });
                                    handleWish(item.id);
                                  }}
                                >
                                  <FaRegHeart />
                                </Link>
                              </div>
                            </div>
                            <div className="product-txt-position ">
                              <h4 style={{ backgroundColor: item?.color }}>
                                {item?.tag}
                              </h4>
                            </div>
                          </div>
                          <h3>{item?.name}</h3>
                          <h5>
                            {item?.price} $<span>{item?.base_price} $</span>
                          </h5>
                        </div>
                      </Link>
                    </div>
                  );
                })
              )}
              {MainData?.length && isLoad ? (
                <Paggination
                  currentPage={currentPage}
                  nPage={nPage}
                  handleChangePage={handleChangePage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {<Footer />}
    </div>
  );
}

export default Shop;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../redux/userSlice";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import { GetUserData } from "../redux/userSliceApi";

const useGetUser = () => {
  const UserData = useSelector(getUserDetail);
  const [cookie] = useCookies(["user"]);
  const dispatch = useDispatch();
  if (!UserData && cookie.user) {
    dispatch(GetUserData({ id: cookie.user }));
  }
};

export default useGetUser;

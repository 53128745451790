import { createSlice } from "@reduxjs/toolkit";
import {
  AddCart,
  AddWishApi,
  GetUserData,
  RemoveCartApi,
  RemoveWishApi,
} from "./userSliceApi";

// API

const initialState = {
  userInfo: null,
  userCarts: [],
  userWish: [],
  CartsDetail: [],
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addMultiCarts: (state, action) => {
      state.userCarts = [...state.userCarts, ...action.payload];
    },
    RemoveWishList: (state, action) => {
      state.userWish = state.userWish.filter((el) => action.payload != el);
    },
    AddCartsDetail: (state, action) => {
      state.CartsDetail = action.payload;
    },
    AddQuantity: (state, action) => {
      let id = state.CartsDetail.findIndex((el) => el.id == action.payload);
      state.CartsDetail[id].quantity++;
    },
    RemoveQuantity: (state, action) => {
      let id = state.CartsDetail.findIndex((el) => el.id == action.payload);
      state.CartsDetail[id].quantity--;
    },
    RemoveCarts: (state, action) => {
      state.CartsDetail = state.CartsDetail.filter(
        (el) =>
          el.id != action.payload.user_id && el.id != action.payload.product_id
      );

      state.userCarts = state.userCarts.filter(
        (el) => el != action.payload.product_id
      );
    },
    UploadImage: (state, action) => {
      state.userInfo.image = action.payload;
    },
    UpdateUser: (state, action) => {
      state.userInfo = { ...state.userInfo, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserData.fulfilled, (state, action) => {
        state.userInfo = action.payload.UserData;
        state.userWish = action.payload.WishList;
        state.userCarts = action.payload.Carts;
      })
      .addCase(AddCart.fulfilled, (state, action) => {
        state.userCarts.push(action.payload);
      })
      .addCase(RemoveCartApi.fulfilled, (state, action) => {
        state.userCarts = state.userCarts.filter((el) => el != action.payload);
      })
      .addCase(AddWishApi.fulfilled, (state, action) => {
        state.userWish.push(action.payload);
      })
      .addCase(RemoveWishApi.fulfilled, (state, action) => {
        state.userWish = state.userWish.filter((el) => el != action.payload);
      });
  },
});

export const {
  addMultiCarts,
  RemoveWishList,
  AddCartsDetail,
  AddQuantity,
  RemoveQuantity,
  RemoveCarts,
  UploadImage,
  UpdateUser,
} = UserSlice.actions;
export const getUserDetail = (state) => state.userData.userInfo;
export const GetCarts = (state) => state.userData.userCarts;
export const ShowWishList = (state) => state.userData.userWish;
export const CartsDetail = (state) => state.userData.CartsDetail;

export default UserSlice.reducer;
